    /* Variables */
    $white: #FFFFFF;
    $black: #000000;
    $font: 'Ubuntu', sans-serif !important;
    $font-family-alt: 'Roboto', sans-serif !important;
    /* Variables */
    
   
    .category_slider {
        padding-top: 15px;
        margin-left: -12px;
        margin-right: -12px;
        @media (min-width: 768px) {
            margin-left: -20px;
            margin-right: -20px;
        }
        @media (min-width: 1200px) {
            padding-top: 28px;
        }
        .slidecols {
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 12px;
            padding-bottom: 12px;
            @media (min-width: 768px) {
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
        .slide_item {
            position: relative;
            background-color: $white;
            border-radius: 22px;
            max-width: 350px;
            margin: auto;
            .imgsec {
                line-height: 0;
                display: block;
                max-width: 100%;
                margin: auto;
                img {
                    max-width: 100%;
                    border-radius: 22px 22px 0 0;
                }
            }
            .detail_sec {
                padding: 18px 12px 22px;
                display: inline-flex;
                @media (min-width: 768px) {
                    padding: 20px 12px 25px;
                }
                @media (min-width: 992px) {
                    padding: 25px 12px 30px;
                }
                @media (min-width: 1400px) {
                    padding: 40px 12px 49px;
                }
                .itemtitle {
                    font-weight: 700;
                    color: #2E2D26;
                    font-size: 18px;
                    @media (min-width: 768px) {
                        font-size: 19px;
                    }
                    @media (min-width: 1400px) {
                        font-size: 24px;
                    }
                }
                .price {
                    font-weight: 700;
                    color: #797979;
                    padding: 0 10px;
                    font-size: 18px;
                    @media (min-width: 768px) {
                        font-size: 19px;
                        padding: 0 5px;
                    }
                    @media (min-width: 1400px) {
                        font-size: 24px;
                        padding: 0 15px;
                    }
                }
                .dwnld_icon{
                    max-width: 20px;
                    line-height: 1.8;
                    padding-top: 6px;
                    @media (min-width: 768px) {
                        max-width: 25px;
                    }
                    @media (min-width: 1200px) {
                        max-width: 30px;
                        padding-top: 6px;
                    }
                    @media (min-width: 1400px) {
                        line-height: 2;
                    }
                    a {
                        img {
                            max-width: 80%;
                            @media (min-width: 1200px) {
                                font-size: 12px;
                            }
                            @media (min-width: 1400px) {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }