

.map_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    .map_wrapper_inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        .map_main{
            width: 100%;
            height: 100%;
            iframe,
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
  
             > div > div:nth-child(2) {
                opacity: 0;
                pointer-events: none;
             }
        }
    }
    .map_filter{
        width: 100%;
        max-width: 515px;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 175px;
        left: 65px;
        transition: .6s all ease-in-out;
        transform-origin: top center;
        .landing_search_result {
            top: 84px !important;
            z-index: 9999;
        }
        @media (max-width: 1599.98px) {
            max-width: 400px;
            top: 145px;
            .landing_search_result {
                top: 64px !important;
            }
        }
        @media (max-width: 1199px) {
            top: 145px;
        }
        
        @media (max-width: 576.98px) {
            left: 0px;
            right: 0px;
            margin: auto;
            top: auto;
            bottom: 0%;
            max-width: calc(100% - 30px);
            z-index: 9;
        }
        .map_filter_header{
            width: 100%;
            display: flex;
            position: relative;
            background-color: #0079FF;
            border-radius: 14px;
            overflow-y: hidden;
            align-items: center;
            padding: 25px;
            z-index: 0;
            @media (max-width: 1599.98px) {
                padding: 15px 25px;
            }
            @media (max-width: 1023.98px) {
                padding: 13px 15px;
            }
            
            &::after{
                width: 125px;
                height: 7px;
                background-color: #fff;
                border-radius: 2px;
                content: '';
                position: absolute;
                right: 0;
                left: 0;
                margin: auto;
                bottom: -3px;
                @media (max-width: 576.98px) {
                    width: 52px;
                }
            }
            .map_search_icon{
                width: 25px;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-right: 30px;
                /* margin-left: auto; */
                @media (max-width: 576.98px) {
                    margin-right: 5px;
                }
                span{
                    font-size: 25px;
                    @media (max-width: 576.98px) {
                        font-size: 18px;
                    }
                }
            }
            .map_input_wrapper{
                width: 100%;
                flex: 1;
                position: relative;
                display: flex;
                flex-direction: column;
                position: relative;
                max-width: 220px;
                input{
                    border: none;
                    background-color: transparent;
                    color: #fff;
                    text-decoration: none;
                    line-height: 1;
                    font-weight: 300;
                    font-family: 'HelveticaNeue';
                    font-size: 26px;
                    text-align: left;
                    @media (max-width: 1599.98px) {
                        font-size: 20px;
                    }
                    @media (max-width: 576.98px) {
                        font-size: 17px;
                    }
                    &:focus{
                        outline: none;
                        border: none;
                        box-shadow: none;
                    }
                    &::-webkit-input-placeholder { /* Edge */
                         color: #fff;
                         opacity: 1;
                        text-decoration: none;
                        line-height: 1.5;
                        font-weight: 300;
                        text-align: left;
                    }
                      
                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: #fff;
                        opacity: 1;
                        text-decoration: none;
                        line-height: 1.5;
                        font-weight: 300;
                        text-align: left;
                    }
                    
                    &::placeholder {
                        color: #fff;
                        opacity: 1;
                        text-decoration: none;
                        line-height: 1.5;
                        font-weight: 300;
                        text-align: left;
                    }
                }
            }
            .map_action_wrapper{
                width: 33px;
                height: 33px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                @media (max-width: 576.98px) {
                    width: 30px;
                    height: 30px;
                  }
                .map_message_icon{
                    position: absolute;
                    opacity: 0;
                    transition: .3s opacity ease-in-out;
                    border: none;
                    background-color: transparent;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    cursor: pointer;
                    pointer-events: none;
                    &.active{
                        opacity: .5;
                        pointer-events: all;
                    }
                    &.active:hover{
                        opacity: 1;
                    }
                    span{
                        font-size: 33px;
                        @media (max-width: 576.98px) {
                          font-size: 20px;
                        }
                    }
                }
                .map_camera_icon{
                    position: absolute;
                    opacity: 0;
                    transition: .3s opacity ease-in-out;
                    border: none;
                    background-color: transparent;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    cursor: pointer;
                    pointer-events: none;
                    &.active{
                        opacity: .5;
                        pointer-events: all;
                        
                    }
                    &.active:hover{
                        opacity: 1;
                    }
                    span{
                        font-size: 33px;
                        @media (max-width: 576.98px) {
                            font-size: 20px;
                          }
                    }
                }
            }
        }
        
  
    .landing_search_result {
      width: 100%;
      top: calc(100% + 15px);
      position: absolute;
      background-color: #fff;
      max-width: 346px;
      height: auto;
      max-height: 300px;
      border-radius: 15px;
      overflow: hidden;
      overflow-y: auto;
      padding-top: 8px;
      box-shadow: 1px 1px 50px -12px rgba(0, 0, 0, 0.2);
      .landing_search_result_inner {
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;
          .lading_search_result_item {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              position: relative;
              flex-direction: column;
              margin-bottom: 8px;
              border: none;
              -webkit-appearance: none;
              background-color: transparent;
              transition: 0.3s all ease-in-out;
              cursor: pointer;
              &:hover{
                  background-color: #F5F5F5;
              }
              .landing_search_item_inner {
                  width: 100%;
                  display: flex;
                  position: relative;
                  padding: 6px 16px;
                  align-items: center;
                  .landing_image_wrapper {
                      width: 36px;
                      display: flex;
                      flex-direction: column;
                      position: relative;
                      border-radius: 10px;
                      overflow: hidden;
                      .landing_search_icon_container {
                          width: 100%;
                          position: relative;
                          padding-bottom: calc((36 / 36) * 100%);
                          img {
                              position: absolute;
                              left: 0;
                              top: 0;
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              object-position: center;
                            }
                        }
                    }
                }
                h3 {
                  margin-top: 0;
                  margin-bottom: 0;
                  font-size: 18px;
                  line-height: 1.5;
                  color: #222222;
                  font-family: "HelveticaNeue";
                  font-weight: 400;
                  padding-left: 13px;
                }
            }
        }
    }
    @media (max-height: 740px) {
      .landing_search_result {
        height: auto;
        max-height: 200px;
      }
    }
    @media (max-width: 576px) {
      .landing_search_result {
        max-width: 100%;
        top: calc(100% + 5px);
        height: auto;
        max-height: 250px;
      }
    }
    @media (max-width: 1599px) {
      .landing_search_result .landing_search_result_inner .lading_search_result_item .landing_search_item_inner {
        padding: 5px 14px;
      }
    }
    @media (max-width: 1599px) {
      .landing_search_result .landing_search_result_inner .lading_search_result_item h3 {
        font-size: 16px;
      }
    }
    @media (max-width: 576px) {
      .landing_search_result .landing_search_result_inner .lading_search_result_item h3 {
        font-size: 14px;
      }
    }
    
        .map_info_container{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            border-radius: 14px;
            overflow: hidden;
            box-shadow: 1px 1px 50px -12px rgba(0, 0, 0, 0.20);
            @media (min-width: 1024px) {
                opacity: 1;
                transform: translateY(0px);
                transition: .3s all ease-in-out;
                transition-delay: .6s;
            }
            @media (max-width: 1023.98px) {
                    &.active{
                        opacity: 1;
                        pointer-events: all;
                    }
               
            }
            @media (max-width: 576.98px) {
                width: calc(100% + 30px);
                margin: 0px -15px;
            }
            
            
            .map_info_inner_scroll{
                width: 100%;
                display: flex;
                flex-direction: column;
                max-height: calc(100vh - 348px);
                overflow:hidden;
                overflow-y: auto;
                padding: 22px 25px;
                padding-bottom: 0px;
                background-color: #fff;
                z-index: 0;
                &::-webkit-scrollbar {
                    width: 12px;
                }
                &::-webkit-scrollbar-thumb {
                    height: 6px;
                    border: 4px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                    -webkit-border-radius: 7px;
                    background-color: #DDDDDD;
                }
                &::-webkit-scrollbar-button {
                    width: 0;
                    height: 0;
                    display: none;
                }
                &::-webkit-scrollbar-corner {
                    background-color: transparent;
                }
                @media (max-width : 1599.98px) {
                    max-height: calc(100vh - 300px);
                }
                @media (max-width : 1199px) {
                    max-height: calc(100vh - 300px);
                }
                @media (max-width : 1023.98px) {
                    max-height:  350px;
                    padding: 18px 15px;
                    padding-bottom: 0;
                }
                @media (max-width : 1023.98px) {
                    max-height:  350px;
                    padding: 18px 15px;
                    padding-bottom: 0;
                }
                @media (max-height: 740px) and (max-width : 1023.98px) {
                    max-height: calc(100vh - 366px);
                } 
                @media (max-height: 740px) and (max-width : 576.98px) {
                    max-height: 320px;
                } 
                @media (max-width : 576.98px) {
                    max-height: 370px;
                }   
                
            }
            .map_info_head{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: 25px;
                @media (max-width : 1599.98px) {
                    margin-bottom: 15px;
                }
                @media (max-width : 576.98px) {
                    margin-bottom: 8px;
                }
                h3{
                    font-size: 26px;
                    line-height: 1.5;
                    color: #000;
                    text-align: center;
                    width: 100%;
                    font-family: 'HelveticaNeue';
                    font-weight: 700;
                    margin: 0;
                    margin-bottom: 5px;
                    @media (max-width: 1599.98px) {
                        font-size: 18px;
                    }
                    @media (max-width: 576.98px) {
                        font-size: 22px;
                        line-height: 1.35;
                    }
                }
                p{
                    font-size: 16px;
                    line-height: 1.5;
                    color: #000;
                    text-align: center;
                    width: 100%;
                    font-family: 'HelveticaNeue';
                    font-weight: 300;
                    margin: 0;
                    @media (max-width: 1599.98px) {
                        font-size:15px;
                    }
                }
            }
            .map_info_filter{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: 30px;
                @media (max-width: 1599.98px) {
                    margin-bottom: 15px;
                }
                .map_info_filter_head{
                    width: 100%;
                    position: relative;
                    margin-bottom: 10px;
                    h4{
                        font-size: 16px;
                        line-height: 1.5;
                        color: #000;
                        text-decoration: none;
                        width: max-content;
                        position: relative;
                        font-size: 16px;
                        font-family: "HelveticaNeue";
                        font-weight: 600;
                        margin-top: 10px;
                        margin-bottom: 0;
                        margin-top: 0;
                        @media (max-width: 576.98px) {
                            font-size: 12px;
                            font-weight: 600;
                        }
  
                        &::after{
                            content: "";
                            position: absolute;
                            right: -19px;
                            width: 0px;
                            height: 0px;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 6px solid #A2A2A2;
                            top: 9px;
                        }
                    }
                }
            }
            .map_filter_items{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                background-color: #F4F9FF;
                padding: 17px 24px;
                border-radius: 4px;
                margin-bottom: 24px;
                @media (max-width: 1599.98px) {
                    padding: 14px 20px;
                    margin-bottom: 15px;
                }
                @media (max-width: 576.95px) {
                    flex-direction: row;
                    padding: 7px 3px;
                    margin-bottom: 8px;
                }
                .mapinfo_select{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    @media (max-width: 576.95px) {
                       padding: 0px 5px;
                       margin-bottom: 0;
                    }
                    select{
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    input,
                    .map_filter_custom_head{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        font-size: 14px;
                        line-height: 1.5;
                        color: #000;
                        text-decoration: none;
                        font-weight: 400;
                        padding: 3.5px 8px;
                        border-radius: 4px;
                        border: 1px solid #E1E4E7;
                        background-color: #fff;
                        cursor: pointer;
                        &::placeholder{
                            color: #000;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            right: 15px;
                            width: 0px;
                            height: 0px;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 6px solid #A2A2A2;
                            top: 0px;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                    .react-datepicker__input_container{
                        position: relative;
                        &::after{
                            content: "";
                            position: absolute;
                            right: 15px;
                            width: 0px;
                            height: 0px;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 6px solid #A2A2A2;
                            top: 0px;
                            bottom: 0;
                            margin: auto;
                        }
                        input::after{
                            display: none;
                        }
                    }
                    .map_filter_custom_list{
                        position: absolute;
                        top: calc(100% + 2px);
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding: 8px;
                        border-radius: 4px;
                        border: 1px solid #E1E4E7;
                        background-color: #fff;
                        max-height: 132px;
                        padding-bottom: 22px;
                        opacity: 0;
                        pointer-events: none;
                        transition: .3s opacity ease-in-out;
                        z-index: 9;
                        ul{
                            margin: 0px;
                            padding: 0px;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            height: 100%;
                            overflow: hidden;
                            overflow-y: auto;
                            li{
                                font-size: 14px;
                                line-height: 1.5;
                                color: #000;
                                font-weight: 400;
                                margin-bottom: 5px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .know_more_button{
                            height: 22px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            width: 100%;
                            height: 22px;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            overflow: hidden;
                            z-index: 1;
                            button{
                                width: max-content;
                                border: none;
                                background-color: transparent;
                                appearance: none;
                                cursor: pointer;
                            }
                            span{
                                font-size: 15px;
                            }
                        }
                    }
                    &.active{
                        .map_filter_custom_list{
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
                
            }
  
            .action_wrapper{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                padding-top: 21.5px;
                border-top: 1px solid #E8E8E8;
                @media (max-width: 1599.98px) {
                    padding-top: 15px;
                }
                @media (max-width: 576.98px) {
                   padding-top: 5px;
                }
                
  
                .cancel-btn{
                    font-size: 14px;
                    line-height: 1;
                    color: #000;
                    font-weight: 400;
                    border: none;
                    background-color: transparent;
                    margin-right: 17px;
                    cursor: pointer;
                    transition: .3s opacity ease-in-out;
                    &:hover{
                        opacity: .5;
                    }
                    @media (max-width: 576.98px) {
                        margin-left: auto;
                    }
                }
                .apply-filter_btn{
                    font-size: 14px;
                    line-height: 1;
                    color: #fff;
                    border-radius: 4px;
                    padding: 6px 8px;
                    font-weight: 400;
                    background-color: #00A841;
                    margin-right: 17px;
                    border: none;
                    transition: .3s background-color ease-in-out;
                    cursor: pointer;
                    &:hover{
                        background-color: #018c36;
                    }
                }
            }
  
            .filter_result_wrapper{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                position: relative;
                overflow: hidden;
                overflow-y: auto;
                @media (max-width:1199px) {
                    overflow: visible;
                    overflow-y: visible;
                }
  
                @media (max-width: 576.98px) {
                    overflow: hidden;
                    overflow-x: auto;
                    overflow-y: hidden;
                    display: block;
                    white-space: nowrap;
                    padding-bottom: 30px;
                }
  
                &::-webkit-scrollbar {
                    width: 12px;
                }
                &::-webkit-scrollbar-thumb {
                    height: 6px;
                    border: 4px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                    -webkit-border-radius: 7px;
                    background-color: #DDDDDD;
                }
                &::-webkit-scrollbar-button {
                    width: 0;
                    height: 0;
                    display: none;
                }
                &::-webkit-scrollbar-corner {
                    background-color: transparent;
                }
        
                .filter_result_item{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    align-items: flex-start;
                    margin-bottom: 20px;
                    cursor: pointer;
                    @media (max-width: 576px) {
                        width: 96px;
                        display: inline-block;
                        margin-right: 8px;
                        overflow: hidden;
                        margin-bottom: 0;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .filter_result_image{
                        width: 100%;
                        max-width: 224px;
                        position: relative;
                        border-radius: 8px;
                        overflow: hidden;
                        @media (max-width: 1599.98px) {
                            max-width: 155px;
                        }
                        @media (max-width: 576.98px) {
                            max-width: 96px;
                            margin-bottom: 0px;
                            &::after{
                                content: '';
                                background: rgb(255,255,255);
                                background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                                background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                                background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                height: 55px;
                                pointer-events: none;
                            }
                        }
                        .filter_result_image_container{
                            width: 100%;
                            position: relative;
                            padding-bottom: calc((83 / 224) * 100%);
                            @media (max-width: 576.98px) {
                                padding-bottom: calc((96 / 96) * 100%);
                            }
                            img{
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
                .filter_result_content{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding-left: 17px;
                    @media (max-width: 576px) {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        padding: 6px;
                        justify-content: flex-end;
                        pointer-events: none;
                    }
                    h4{
                        width: 100%;
                        font-size: 19px;
                        line-height: 1.3;
                        margin-bottom: 3px;
                        margin-top: 0;
                        @media (max-width: 1599.98px) {
                            font-size: 16px;
                        }
                        @media (max-width: 576px) {
                            font-size: 10px;
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-bottom: 0;
                        }
                    }
                    p{
                        width: 100%;
                        font-size: 14px;
                        line-height: 1.5;
                        margin-bottom: 6px;
                        margin-top: 0;
                        @media (max-width: 1599.98px) {
                            margin-bottom: 3px;
                        }
                        @media (max-width: 576px) {
                            font-size: 8px;
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    a{
                        width: max-content;
                        @media (max-width: 576px) {
                            position: absolute;
                            opacity: 0;
                            z-index: 1;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    span::before{
                        color: #4043B4;
                    }
                }
            }
            .read_more_wrapper{
                width: 100%;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 55px;
                @media (max-height: 740px) and (max-width : 1023.98px){
                    height: 33px;
                }
                @media (max-width : 576.98px){
                   display: none;
                }
                
                button{
                    background-color: transparent;
                    border: none;
                    appearance: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        font-size: 30px;
                    }
                }
            }
        }
    }
  
  
  
  
  
  
    
  }
  
  
  .main_wrapper{
    width: 100%;
    height: 100%;
    overflow: hidden;
    .main_wrapper_inner{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    &.active{
        .lading_page_wrapper{
            opacity: 1;
            pointer-events: all;
            transform: scale(1);
            z-index: 9;
        }
        .map_filter{
            top: calc(20% + 196px);
            left: 50%;
            transform: translate(-50% , 0%) scale(1.36);
            @media (max-width: 1599.98px) {
                top: calc(20% + 156px);
                left: 50%;
                transform: translate(-50%, 0%) scale(1.38);
            }
            @media (max-width: 991px) {
                top: calc(20% + 132px);
                left: 47%;
                transform: translate(-50%, 0%) scale(1.38);
            }
            @media (max-width: 576.98px) {
                top: calc(20% + 107px);
                left: 50%;
                transform: translate(-50%, 0%) scale(1.13);
            }
        }
        .map_info_container{
            @media (min-width: 1024px) {
                opacity: 0 !important;
                transform: translateY(-50px) !important;
            }
        }
    }
  
  
  
  
  
    
  }
  
  
  
  
  .floating_right_icon{
    position: fixed;
    right: 65px;
    top: 100px;
    width: 48px;
    @media (max-width: 767.98px) {
        right: 15px;
    }
    @media (max-width: 576.98px) {
        top: 20px;
    }
    .floating_right_icon_inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 1px 1px 11px -3px #00000042;
        overflow: hidden;
        .layer_wrapper{
            width: 48px;
            height: 48px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            button{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                height: 100%;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                color: #000;
                border: none;
                span{
                    font-size: 20px;
                    color: #000;
                    &::before{
                        color: inherit;
                    }
                }
            }
        }
        .message_wrapper{
            width: 48px;
            height: 48px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            button{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                height: 100%;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                color: #000;
                border: none;
                span{
                    font-size: 20px;
                }
            }
        }
        .direction_wrapper{
            width: 48px;
            height: 48px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            button{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                height: 100%;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                color: #000;
                border: none;
                span{
                    font-size: 20px;
                }
            }
        }
    }
  }
  
  
  
  .floating_right_icon{
      position: fixed;
      right: 65px;
      top: 100px;
      width: 48px;
      @media (max-width: 767.98px) {
          right: 15px;
      }
      @media (max-width: 576.98px) {
          top: 20px;
      }
      .floating_right_icon_inner{
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;
          background-color: #fff;
          border-radius: 6px;
          box-shadow: 1px 1px 11px -3px #00000042;
          overflow: hidden;
          .layer_wrapper{
              width: 48px;
              height: 48px;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              button{
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  position: relative;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                  background-color: transparent;
                  color: #000;
                  border: none;
                  span{
                      font-size: 20px;
                      color: #000;
                      &::before{
                          color: inherit;
                      }
                  }
              }
          }
          .message_wrapper{
              width: 48px;
              height: 48px;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              button{
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  position: relative;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                  background-color: transparent;
                  color: #000;
                  border: none;
                  span{
                      font-size: 20px;
                  }
              }
          }
          .direction_wrapper{
              width: 48px;
              height: 48px;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              button{
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  position: relative;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                  background-color: transparent;
                  color: #000;
                  border: none;
                  span{
                      font-size: 20px;
                  }
              }
          }
      }
    }
  
    .send_message_item{
      width: max-content;
      font-size: 20px;
      line-height: 1;
      color: #fff;
      background-color: #0079FF;
      padding: 18px 70px;
      border-radius: 10px 10px 0px 0px ;
      position: fixed;
      bottom: 0;
      right: 40px;
      border: none;
  }
  @media (max-width: 1599px) {
      .send_message_item{
          font-size: 18px;
          padding: 15px 60px;
      }
  }
  
  @media (max-width: 1199px) {
      .send_message_item{
          font-size: 16px;
          padding: 13px 40px;
      }
  }
  
  @media (max-width: 1023.98px) {
      .send_message_item{
          display: none;
      }
  }