
/* Variables */
$white: #FFFFFF;
$black: #000000;
$font: 'Ubuntu', sans-serif !important;
$font-family-alt: 'Roboto', sans-serif !important;
/* Variables */

/* Header starts */
.siteheader {
    .topheader {
        padding: 15px 0;
        position: relative;
        z-index: 1;
        .smedia {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            list-style: none;
            margin: 0;
            li {
                &+li {
                    margin-left: 33px;
                }
            }
        }
    }
    .mainheader {
        position: relative;
        z-index: 999;
        &:before {
            content: '';
            display: block;
            width: 20%;
            background-color: $white;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
        &:after {
            content: '';
            display: block;
            width: 50%;
            background-color: #0079FF;
            height: calc(100% - 20px);
            position: absolute;
            right: 0;
            top: 20px;
        }
        .headerwrap {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            z-index: 1;
            margin-top: -20px;
            .logosec {
                max-width: 40%;
                flex-basis: 40%;
                padding-top: 30px;
                padding-bottom: 30px;
                background-color: $white;
                position: relative;
                @media (min-width: 1200px) {
                    max-width: 30%;
                    flex-basis: 30%;
                    padding-top: 45px;
                    padding-bottom: 45px;
                }
                &:before {
                    content: '';
                    display: block;
                    position:absolute;
                    top:0;
                    right:-30px;
                    width: 0; 
                    height: 0; 
                    border-top: 25px solid transparent;
                    border-right: 25px solid transparent;
                    border-bottom: 114px solid $white;
                    border-left: 5px solid $white;
                    @media (max-width: 1199px) {
                        border-bottom-width: 84px;
                    }
                    @media (max-width: 1199px) {
                        border-bottom-width: 84px;
                    }
                    @media (max-width: 991px) {
                        border-bottom-width: 80px;
                    }
                    @media (max-width: 767px) {
                        border-top-width: 15px;
                        border-bottom-width: 78px;
                    }
                    @media (max-width: 575px) {
                        border-top-width: 15px;
                        border-bottom-width: 74px;
                    }
                    @media (max-width: 480px) {
                        border-top-width: 15px;
                        border-bottom-width: 70px;
                    }
                }
                a {
                    display: inline-block;
                    line-height: 0;
                    max-width: 100%;
                }
                img {
                    max-width: 100%;
                }
            }
            .rightsec {
                max-width: 60%;
                flex-basis: 60%;
                padding-left: 40px;
                background-color: #0079FF;
                display: flex;
                align-items: center;
                margin-top: 20px;
                @media (min-width: 1200px) {
                    max-width: 70%;
                    flex-basis: 70%;
                }
                @media (min-width: 1400px) {
                    padding-left: 80px;
                }
                
                /* Navigation starts */
                .hamburger_icon {
                    width: 50px;
                    height: 40px;
                    padding: 10px;
                    background-color: $white;
                    margin-left: auto;
                    cursor: pointer;
                    border-radius: 5px;
                    @media (min-width: 1200px) {
                        display: none;
                    }
                    span {
                        display: block;
                        height: 4px;
                        width: 100%;
                        background: #313131;
                        &+span {
                            margin-top: 4px;
                        }
                    }
                }
                .close_icon {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    width: 30px;
                    height: 30px;
                    background-color: $white;
                    margin-left: auto;
                    cursor: pointer;
                    @media (min-width: 1200px) {
                        display: none;
                    }
                    span{
                        width: 25px ;
                        height: 3px;
                        background-color: #000;
                        content: '';
                        position: absolute;
                        top: 12px;
                        @media (max-width: 576px) {
                            width: 20px ;
                        }
                        &:nth-child(1){
                            transform: rotate(45deg);
                        }
                        &:nth-child(2){
                            transform: rotate(-45deg);
                        }
                    }
                }

                /* Main Menu starts */
                .mainav {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;
                    @media (max-width: 1199px) {
                        display: block;
                        position: fixed;
                        max-width: 100%;
                        top: 0;
                        padding: 45px;
                        padding-top: 35px;
                        right: 0;
                        height: 100%;
                        transition: all ease-in-out 0.3s;
                        z-index: 3;
                        width: 100%;
                        height: 100%;
                        overflow: auto;
                        opacity: 0;
                        -webkit-transform: scaleY(0);
                        -ms-transform: scaleY(0);
                        transform: scaleY(0);
                        -webkit-transition: opacity .3s ease-in, transfrom .3s ease-out;
                        -o-transition: opacity .3s ease-in, transfrom .3s ease-out;
                        transition: opacity .3s ease-in, transfrom .3s ease-out;
                        -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
                        background: #fff;
                        &.active {
                            opacity: 1;
                            transform: scale(1,1);
                        }
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        @media (min-width: 1200px) {
                            display: flex;
                            flex-wrap: wrap;
                            li {
                                color: $white;
                                position: relative;
                                font-family: $font;
                                &::before {
                                    content: '';
                                    display: block;
                                    width: 0%;
                                    opacity: 0;
                                    height: 3px;
                                    position: absolute;
                                    bottom: -10px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    background-color: #E33277;
                                }
                                &+li {
                                    margin-left: 35px;
                                    @media (min-width: 1400px) {
                                        margin-left: 50px;
                                    }
                                }
                                a {
                                    text-decoration: none;
                                    color: $white;
                                }
                                &:global(.active), &.active {
                                    &::before {
                                        width: 100%;
                                        opacity: 1;
                                    }
                                    a {
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                    .mainmenu {
                        width: 100%;
                        @media (min-width: 1200px) {
                            max-width: 60%;
                            flex-basis: 60%;
                            padding-right: 45px;
                        }
                        @media (max-width: 1199px) {
                            li {
                                width: 100%;
                                padding: 10px 10px;
                                border-bottom: 1px solid #e9e9e9;
                                a {
                                    color: $black;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
                /* Main menu end */

                /* Search and Login starts*/
                .seacrh_admin {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    max-width: 40%;
                    flex-basis: 40%;
                    @media (max-width: 1199px) {
                        margin-top: 40px;
                        max-width: 370px;
                        flex-basis: 370px;
                    }
                    @media (max-width: 767px) {
                        max-width: 100%;
                        flex-basis: 100%;
                    }
                    .searchbar {
                        max-width: 56%;
                        flex-basis: 56%;
                        padding-right: 15px;
                        @media (max-width: 1399px) {
                            padding-right: 7px;
                        }
                        @media (max-width: 767px) {
                            max-width: 100%;
                            flex-basis: 100%;
                            padding: 0 0 30px;
                        }
                        .input_holder {
                            position: relative;
                            .form_control {
                                height: 60px;
                                border: 2px solid $white;
                                border-radius: 30px;
                                background: transparent;
                                padding-left: 20px;
                                padding-right: 50px;
                                width: 100%;
                                outline: none;
                                color: $white;
                                @media (max-width: 1399px) {
                                    height: 50px;
                                }
                                @media (max-width: 1199px) {
                                    border-color: #0079FF;
                                    color: $black;
                                }
                            }
                            .searchicon {
                                background: transparent;
                                border: none;
                                outline: none;
                                box-shadow: none;
                                position: absolute;
                                right: 25px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                top: 0;
                                svg {
                                    fill: $white;
                                    @media (max-width: 1199px) {
                                        fill: #0079FF;
                                    }
                                }
                                @media (max-width: 1399px) {
                                    right: 15px;
                                }
                            }
                        }
                    }
                    .admin_login {
                        max-width: 44%;
                        flex-basis: 44%;
                        padding-left: 15px;
                        @media (max-width: 1399px) {
                            padding-left: 7px;
                        }
                        @media (max-width: 767px) {
                            max-width: 100%;
                            flex-basis: 100%;
                            padding: 0;
                        }
                        .wrapper {
                            height: 60px;
                            border-radius: 30px;
                            background: $white;
                            padding: 0px 18px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            @media (max-width: 1399px) {
                                height: 50px;
                                padding: 0px 15px;
                            }
                            @media (max-width: 1199px) {
                                background: #0079FF;
                            }
                            .hamb {
                                width: 30px;
                                height: 20px;
                                margin-right: auto;
                                cursor: pointer;
                                @media (max-width: 1399px) {
                                    width: 28px;
                                    height: 20px;
                                }
                                span {
                                    display: block;
                                    height: 4px;
                                    width: 100%;
                                    background: #313131;
                                    @media (max-width: 1199px) {
                                        background: $white;
                                    }
                                    &+span {
                                        margin-top: 4px;
                                    }
                                }
                            }
                            .avatar {
                                margin-left: auto;
                                width: 44px;
                                height: 44px;
                                border-radius: 50%;
                                background-color: #707070;
                                max-width: 100%;
                                img {
                                    max-width: 100%;
                                }
                                @media (max-width: 1399px) {
                                    width: 35px;
                                    height: 35px;
                                }
                            }
                        }
                    }
                }
                /* Search and Login end*/

                /* Navigation end*/
            }
        }
    }
}
.container {
    width: 100%;
    padding-right: .75rem;
    padding-left: .75rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 480px !important;
    @media (min-width: 576px) {
        max-width: 540px !important;
    }
    @media (min-width: 768px) {
        max-width: 720px !important;
    }
    @media (min-width: 992px) {
        max-width: 932px !important;
    }
    @media (min-width: 1200px) {
        max-width: 1140px !important;
    }
    @media (min-width: 1400px) {
        max-width: 1320px !important;
    }
    @media (min-width: 1800px) {
        max-width: 1540px !important;
    }
}
/* Header ends */