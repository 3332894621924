.main-wrapper{
    width: 100%;
    height: 100%;
    // overflow: hidden;
    .main-wrapper-inner{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    &.active{
        .lading-page-wrapper{
            opacity: 1;
            pointer-events: all;
            transform: scale(1);
        }
    }





    
}


.map-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    .map-wrapper-inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        .map-main{
            width: 100%;
            height: 100%;
            iframe,
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    .map-filter{
        width: 100%;
        max-width: 515px;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 175px;
        left: 65px;
        @media (max-width: 1599px) {
            max-width: 400px;
        }
        @media (max-width: 1199px) {
            top: 145px;
        }
        @media (max-width: 1023.98px) {
            left: 0px;
            right: 0px;
            margin: auto;
            top: 100px;
        }
        @media (max-width: 576px) {
            left: 15px;
            max-width: calc(100% - 90px);
            margin: 0;
        }
        .map-filter-header{
            width: 100%;
            display: flex;
            position: relative;
            background-color: #0079FF;
            border-radius: 14px;
            overflow-y: hidden;
            align-items: center;
            padding: 25px;
            @media (max-width: 1023.98px) {
                padding: 15px;
            }
            &::after{
                width: 125px;
                height: 7px;
                background-color: #fff;
                border-radius: 2px;
                content: '';
                position: absolute;
                right: 0;
                left: 0;
                margin: auto;
                bottom: -3px;
            }
            .map-search-icon{
                width: 25px;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-right: 30px;
                @media (max-width: 576px) {
                    margin-right: 5px;
                }
                span{
                    font-size: 25px;
                    @media (max-width: 576px) {
                        font-size: 18px;
                    }
                }
            }
            .map-input-wrapper{
                width: 100%;
                flex: 1;
                position: relative;
                display: flex;
                flex-direction: column;
                position: relative;
                input{
                    border: none;
                    background-color: transparent;
                    color: #fff;
                    text-decoration: none;
                    line-height: 1;
                    font-weight: 300;
                    font-family: 'Helvetica Neue';
                    font-size: 26px;
                    @media (max-width: 1599px) {
                        font-size: 20px;
                    }
                    @media (max-width: 576px) {
                        font-size: 17px;
                    }
                    &:focus{
                        outline: none;
                        border: none;
                        box-shadow: none;
                    }
                    &::-webkit-input-placeholder { /* Edge */
                         color: #fff;
                         opacity: 1;
                        text-decoration: none;
                        line-height: 1.5;
                        font-weight: 300;
                    }
                      
                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: #fff;
                        opacity: 1;
                        text-decoration: none;
                        line-height: 1.5;
                        font-weight: 300;
                    }
                    
                    &::placeholder {
                        color: #fff;
                        opacity: 1;
                        text-decoration: none;
                        line-height: 1.5;
                        font-weight: 300;
                    }
                }
            }
            .map-action-wrapper{
                width: 33px;
                height: 33px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                @media (max-width: 576px) {
                    width: 30px;
                    height: 30px;
                  }
                .map-message-icon{
                    position: absolute;
                    opacity: 0;
                    transition: .3s opacity ease-in-out;
                    border: none;
                    background-color: transparent;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    cursor: pointer;
                    pointer-events: none;
                    &.active{
                        opacity: .5;
                        pointer-events: all;
                    }
                    &.active:hover{
                        opacity: 1;
                    }
                    span{
                        font-size: 33px;
                        @media (max-width: 576px) {
                          font-size: 20px;
                        }
                    }
                }
                .map-camera-icon{
                    position: absolute;
                    opacity: 0;
                    transition: .3s opacity ease-in-out;
                    border: none;
                    background-color: transparent;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    cursor: pointer;
                    pointer-events: none;
                    &.active{
                        opacity: .5;
                        pointer-events: all;
                        
                    }
                    &.active:hover{
                        opacity: 1;
                    }
                    span{
                        font-size: 33px;
                        @media (max-width: 576px) {
                            font-size: 20px;
                          }
                    }
                }
            }
        }
        .map-info-container{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            border-radius: 14px;
            overflow: hidden;
            box-shadow: 1px 1px 50px -12px rgba(0, 0, 0, 0.20);
            @media (max-width: 1023.98px) {
                
                    opacity: 0;
                    pointer-events: none;
                    transition: .3s opacity ease-in-out;
                    &.active{
                        opacity: 1;
                        pointer-events: all;
                    }
               
            }
            
            
            .map-info-inner-scroll{
                width: 100%;
                display: flex;
                flex-direction: column;
                max-height: calc(100vh - 348px);
                overflow:hidden;
                overflow-y: auto;
                padding: 22px 25px;
                padding-bottom: 22px;
                background-color: #fff;
                z-index: 0;
                @media (max-width : 1199px) {
                    max-height: calc(100vh - 308px);
                }
                @media (max-width : 1023.98px) {
                    max-height:  350px;
                    padding: 18px 15px;
                }
                @media (max-height: 740px) and (max-width : 1023.98px) {
                    max-height: calc(100vh - 366px);
                }   
                &::-webkit-scrollbar {
                    width: 12px;
                }
                &::-webkit-scrollbar-thumb {
                    height: 6px;
                    border: 4px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                    -webkit-border-radius: 7px;
                    background-color: #DDDDDD;
                }
                &::-webkit-scrollbar-button {
                    width: 0;
                    height: 0;
                    display: none;
                }
                &::-webkit-scrollbar-corner {
                    background-color: transparent;
                }
            }
            .map-info-head{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: 25px;
                h3{
                    font-size: 26px;
                    line-height: 1.5;
                    color: #000;
                    text-align: center;
                    width: 100%;
                    font-family: 'HelveticaNeue';
                    font-weight: 700;
                    margin: 0;
                    margin-bottom: 5px;
                    @media (max-width: 1599px) {
                        font-size: 22px;
                    }
                    @media (max-width: 576px) {
                        font-size: 18px;
                    }
                }
                p{
                    font-size: 16px;
                    line-height: 1.5;
                    color: #000;
                    text-align: center;
                    width: 100%;
                    font-family: 'HelveticaNeue';
                    font-weight: 300;
                    margin: 0;
                    @media (max-width: 1599px) {
                        font-size: 14px;
                    }
                }
            }
            .map-info-filter{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: 30px;
                .map-info-filter-head{
                    width: 100%;
                    position: relative;
                    margin-bottom: 10px;
                    h4{
                        font-size: 16px;
                        line-height: 1.5;
                        color: #000;
                        text-decoration: none;
                        width: max-content;
                        position: relative;
                        font-size: 16px;
                        font-family: "HelveticaNeue";
                        font-weight: 400;
                        margin-top: 10px;
                        margin-bottom: 0;
                        margin-top: 0;

                        &::after{
                            content: "";
                            position: absolute;
                            right: -19px;
                            width: 0px;
                            height: 0px;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 6px solid #A2A2A2;
                            top: 9px;
                        }
                    }
                }
            }
            .map-filter-items{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                background-color: #F4F9FF;
                padding: 17px 24px;
                border-radius: 4px;
                margin-bottom: 24px;
                @media (max-width: 1599px) {
                    padding: 14px 20px;
                }
                .mapinfo-select{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    select{
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .map-filter-custom-head{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        font-size: 14px;
                        line-height: 1.5;
                        color: #000;
                        text-decoration: none;
                        font-weight: 400;
                        padding: 3.5px 8px;
                        border-radius: 4px;
                        border: 1px solid #E1E4E7;
                        background-color: #fff;
                        &::after{
                            content: "";
                            position: absolute;
                            right: 15px;
                            width: 0px;
                            height: 0px;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 6px solid #A2A2A2;
                            top: 0px;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                    .map-filter-custom-list{
                        position: absolute;
                        top: calc(100% + 2px);
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding: 8px;
                        border-radius: 4px;
                        border: 1px solid #E1E4E7;
                        background-color: #fff;
                        max-height: 132px;
                        padding-bottom: 22px;
                        opacity: 0;
                        pointer-events: none;
                        transition: .3s opacity ease-in-out;
                        z-index: 9;
                        ul{
                            margin: 0px;
                            padding: 0px;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            height: 100%;
                            overflow: hidden;
                            overflow-y: auto;
                            li{
                                font-size: 14px;
                                line-height: 1.5;
                                color: #000;
                                font-weight: 400;
                                margin-bottom: 5px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .know-more-button{
                            height: 22px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            width: 100%;
                            height: 22px;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            overflow: hidden;
                            z-index: 1;
                            button{
                                width: max-content;
                                border: none;
                                background-color: transparent;
                                appearance: none;
                                cursor: pointer;
                            }
                            span{
                                font-size: 15px;
                            }
                        }
                    }
                    &.active{
                        .map-filter-custom-list{
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
                
            }

            .action-wrapper{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                padding-top: 21.5px;
                border-top: 1px solid #E8E8E8;
                .cancel-btn{
                    font-size: 14px;
                    line-height: 1;
                    color: #000;
                    font-weight: 400;
                    border: none;
                    background-color: transparent;
                    margin-right: 17px;
                    cursor: pointer;
                    transition: .3s opacity ease-in-out;
                    &:hover{
                        opacity: .5;
                    }
                }
                .apply-filter-btn{
                    font-size: 14px;
                    line-height: 1;
                    color: #fff;
                    border-radius: 4px;
                    padding: 6px 8px;
                    font-weight: 400;
                    background-color: #00A841;
                    margin-right: 17px;
                    border: none;
                    transition: .3s background-color ease-in-out;
                    cursor: pointer;
                    &:hover{
                        background-color: #018c36;
                    }
                }
            }

            .filter-result_wrapper{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                .filter-result-item{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    align-items: flex-start;
                    margin-bottom: 30px;
                    @media (max-width: 440px) {
                        flex-direction: column;
                        position: relative;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .filter-result-image{
                        width: 100%;
                        max-width: 224px;
                        position: relative;
                        border-radius: 8px;
                        overflow: hidden;
                        @media (max-width: 1599px) {
                            max-width: 155px;
                        }
                        @media (max-width: 440px) {
                            max-width: 100%;
                            margin-bottom: 8px;
                        }
                        .filter-result-image-container{
                            width: 100%;
                            position: relative;
                            padding-bottom: calc((83 / 224) * 100%);
                            img{
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
                .filter-result-content{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding-left: 17px;
                    @media (max-width: 440px) {
                        padding-left: 0;
                    }
                    h4{
                        width: 100%;
                        font-size: 19px;
                        line-height: 1.5;
                        margin-bottom: 3px;
                        margin-top: 0;
                        @media (max-width: 1599px) {
                            font-size: 16px;
                        }
                    }
                    p{
                        width: 100%;
                        font-size: 14px;
                        line-height: 1.5;
                        margin-bottom: 6px;
                        margin-top: 0;
                        @media (max-width: 1599px) {
                            margin-bottom: 3px;
                        }
                    }
                    a{
                        width: max-content;
                    }
                    span::before{
                        color: #4043B4;
                    }
                }
            }
            .read-more-wrapper{
                width: 100%;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 55px;
                @media (max-height: 740px) and (max-width : 1023.98px){
                    height: 33px;
                }
                button{
                    background-color: transparent;
                    border: none;
                    appearance: none;
                    span{
                        font-size: 30px;
                    }
                }
            }
        }
    }



    .map-bottom-stripe{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: absolute  ;
        bottom: 35px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1400px) {
            padding-left: 500px;
            align-items: flex-start;
        }
        @media (max-width: 1199px) {
            bottom: 25px;
        }
        @media (max-width: 1023.98px) {
            padding-left: 0px;
            align-items: center;
            bottom: 15px;
        }
        @media (max-width: 576px) {
            padding-left: 15px;
            align-items: flex-start;
        }
        .map-bottom-inner{
            width: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            .map-bottom-map{
                width: auto;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-right: 16px;
                @media (max-width: 576px) {
                    margin-right: 10px;
                }
                button{
                    width: 64px;
                    height: 64px;
                    border: none;
                    background-color: #0276F2;
                    border-radius: 10px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (max-width: 1599px) {
                        width: 54px;
                        height: 54px;
                    }
                    @media (max-width: 1023.98px) {
                        width: 40px;
                        height: 40px;
                    }
                    span{
                        font-size: 22px;
                    }
                }
            }
            .map-bottom-info{
                width: auto;
                height: 64px;
                border-radius: 10px;
                padding: 8px;
                background-color: #fff;
                display: flex;
                align-items: center;
                box-shadow: 1px 1px 50px -12px rgba(0, 0, 0, 0.20);
                @media (max-width: 1599px) {
                    height: 54px;
                }
                @media (max-width: 1023.98px) {
                    height: 40px;
                }
                @media (max-width: 576px) {
                    border-radius: 6px;
                    padding: 4px;
                }
                .map-bottom-inner{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                }
                .head-map{
                    width: auto;
                    margin-right: 9px;
                    button{
                        height: 46px;
                        color: #0276F2;
                        padding: 0px 17px;
                        background-color: transparent;
                        border-radius: 9.5px;
                        border: 1px solid #0276F2;
                        font-size: 14px;
                        line-height: 1;
                        transition: .3s background-color ease-in-out, .3s color ease-in-out;
                        @media (max-width: 1599px) {
                            height: 44px;
                        }
                        @media (max-width: 1023.98px) {
                            height: 30px;
                            border-radius: 4px;
                            font-size: 13px;
                            padding: 0px 10px;
                        }
                        span::before{
                            transition: .3s background-color ease-in-out, .3s color ease-in-out;
                        }
                        &:hover{
                            background-color: #0276F2;
                            color: #fff;
                            span::before{
                                color: #fff;
                            }
                        }
                    }
                }

                .drivers-map{
                    width: auto;
                    margin-right: 9px;
                    button{
                        height: 46px;
                        color: #0276F2;
                        padding: 0px 17px;
                        background-color: transparent;
                        border-radius: 9.5px;
                        border: 1px solid #0276F2;
                        font-size: 14px;
                        line-height: 1;
                        transition: .3s background-color ease-in-out, .3s color ease-in-out;
                        @media (max-width: 1023.98px) {
                            height: 30px;
                            border-radius: 4px;
                            font-size: 13px;
                            padding: 0px 10px;
                        }
                        span::before{
                            transition: .3s background-color ease-in-out, .3s color ease-in-out;
                        }
                        &:hover{
                            background-color: #0276F2;
                            color: #fff;
                            span::before{
                                color: #fff;
                            }
                        }
                    }
                }


                .user-map{
                    width: auto;
                    button{
                        height: 46px;
                        color: #222222;
                        padding: 0px 17px;
                        background-color: transparent;
                        border-radius: 9.5px;
                        border: 1px solid #F1F2F3;
                        font-size: 14px;
                        line-height: 1;
                        transition: .3s background-color ease-in-out, .3s color ease-in-out;
                        @media (max-width: 1023.98px) {
                            height: 30px;
                            border-radius: 4px;
                            font-size: 13px;
                            padding: 0px 10px;
                        }
                        &:hover{
                            background-color: #F1F2F3;
                        }
                    }
                }
            }
        }
    }



    .map-right-stripe{
        width: 64px;
        display: flex;
        flex-direction: column;
        position: absolute  ;
        bottom: 0;
        top: 0;
        right: 49px;
        display: flex;
        padding-top: 175px;
        padding-bottom: 35px;
        @media (max-width: 1199px) {
            right: 25px;
            padding-bottom: 25px;
        }
        @media (max-width: 767px) {
            right:15px;
            padding-bottom: 15px;
            width: 35px;
            padding-top: 100px;
        }
        .map-right-inner{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .map-right-info{
                width: 64px;
                height: auto;
                border-radius: 10px;
                background-color: #fff;
                display: flex;
                align-items: center;
                flex-direction: column;
                border-radius: 9.5px;
                overflow: hidden;
                box-shadow: 1px 1px 50px -12px rgba(0, 0, 0, 0.20);
                @media (max-width: 1599px) {
                    width: 54px;
                }
                @media (max-width: 1023.98px) {
                    width: 40px;
                }
                .map-right-inner{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                }
                .zoom-in{
                    width: auto;
                    border-bottom: 1px solid #F1F2F3;
                    button{
                        width: 64px;
                        height: 64px;
                        color: #0276F2;

                        background-color: transparent;
                        border: none;
                        font-size: 18px;
                        line-height: 1;
                        transition: .3s background-color ease-in-out, .3s color ease-in-out;
                        @media (max-width: 1599px) {
                            width: 54px;
                            height: 54px;
                        }
                        @media (max-width: 1023.98px) {
                            width: 40px;
                            height: 40px;
                        }
                        span{
                            @media (max-width: 1023.98px) {
                                font-size: 12px;
                            }
                        }
                        &:hover{
                            background-color: #f7f7f7;
                        }
                    }
                }

                .zoom-out{
                    width: auto;
                    button{
                        width: 64px;
                        height: 64px;
                        color: #0276F2;

                        background-color: transparent;
                        border: none;
                        font-size: 18px;
                        line-height: 1;
                        transition: .3s background-color ease-in-out, .3s color ease-in-out;
                        @media (max-width: 1599px) {
                            width: 54px;
                            height: 54px;
                        }
                        @media (max-width: 1023.98px) {
                            width: 40px;
                            height: 40px;
                        }
                        &:hover{
                            background-color: #fafafa;
                        }
                        span{
                            font-size: 18px;
                            @media (max-width: 1023.98px) {
                                font-size: 12px;
                            }
                        }
                    }
                }


                .user-map{
                    width: auto;
                    button{
                        height: 46px;
                        color: #222222;

                        background-color: transparent;
                        border-radius: 9.5px;
                        border: 1px solid #F1F2F3;
                        font-size: 14px;
                        line-height: 1;
                        transition: .3s background-color ease-in-out, .3s color ease-in-out;
                        @media (max-width: 1599px) {
                            width: 54px;
                        }
                        @media (max-width: 1023.98px) {
                            width: 35px;
                        }
                        &:hover{
                            background-color: #fafafa;
                        }
                        span{
                            font-size: 18px;
                            @media (max-width: 1023.98px) {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }

            .point-item{
                width: auto;
                margin-top: 16px;
                button{
                    width: 64px;
                    height: 64px;
                    color: #0276F2;
                    background-color: transparent;
                    border: none;
                    font-size: 18px;
                    border-radius: 9.5px;
                    line-height: 1;
                    background-color: #fff;
                    transition: .3s background-color ease-in-out, .3s color ease-in-out;
                    box-shadow: 1px 1px 50px -12px rgba(0, 0, 0, 0.20);
                    @media (max-width: 1599px) {
                        width: 54px;
                        height: 54px;
                    }
                    @media (max-width: 1023.98px) {
                        width:40px;
                        height:40px;
                    }
                        &:hover{
                            background-color: #fafafa;
                        }
                    span{
                        font-size: 22px;
                        @media (max-width: 1023.98px) {
                            font-size: 15px;
                        }
                    }
                }
            }

            .bottom-icon-1{
                width: auto;
                margin-top: auto;
                @media (max-width: 576px) {
                    margin-top: 16px;
                }
                button{
                    width: 64px;
                    height: 64px;
                    color: #0276F2;
                    background-color: transparent;
                    border: none;
                    font-size: 18px;
                    border-radius: 9.5px;
                    line-height: 1;
                    background-color: #fff;
                    transition: .3s background-color ease-in-out, .3s color ease-in-out;
                    box-shadow: 1px 1px 50px -12px rgba(0, 0, 0, 0.20);
                    @media (max-width: 1599px) {
                        width: 54px;
                        height: 54px;
                    }
                    @media (max-width: 1023.98px) {
                        width: 40px;
                        height: 40px;
                    }
                        &:hover{
                            background-color: #fafafa;
                        }
                    span{
                        font-size: 30px;
                        @media (max-width: 1023.98px) {
                            font-size: 20px;
                        }
                    }
                }
            }

            .bottom-icon-2{
                width: auto;
                margin-top: 16px;
                button{
                    width: 64px;
                    height: 64px;
                    color: #0276F2;
                    background-color: transparent;
                    border: none;
                    font-size: 18px;
                    border-radius: 9.5px;
                    line-height: 1;
                    background-color: #fff;
                    transition: .3s background-color ease-in-out, .3s color ease-in-out;
                    box-shadow: 1px 1px 50px -12px rgba(0, 0, 0, 0.20);
                    @media (max-width: 1599px) {
                        width: 54px;
                        height: 54px;
                    }
                    @media (max-width: 1023.98px) {
                        width: 40px;
                        height: 40px;
                    }
                        &:hover{
                            background-color: #fafafa;
                        }
                    span{
                        font-size: 25px;
                        @media (max-width: 1023.98px) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}