
  .map_filter_header{
    width: 100%;
    max-width: 1146px;
    display: flex;
    position: relative;
    background-color: #0079FF;
    border-radius: 14px;
    overflow-y: hidden;
    align-items: center;
    padding: 25px;
    z-index: 1;
    @media (max-width: 1599.98px) {
        padding: 15px 25px;
        max-width: 990px;
    }
    @media (max-width: 1199.98px) {
      max-width: 767px;
    }
    @media (max-width: 1023.98px) {
        padding: 13px 15px;
        
    }
    @media (max-width: 1023.98px) {
      max-width: 500px;
        
    }
    @media (max-width: 767px) {
      max-width: calc(100% - 30px);
    }
    
    &::after{
        width: 125px;
        height: 7px;
        background-color: #fff;
        border-radius: 2px;
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        bottom: -3px;
        @media (max-width: 576.98px) {
            width: 52px;
        }
    }
    .map_search_icon{
        width: 25px;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-right: 30px;
        @media (max-width: 576.98px) {
            margin-right: 5px;
        }
        span{
            font-size: 25px;
            @media (max-width: 576.98px) {
                font-size: 18px;
            }
        }
    }
    .map_input_wrapper{
        width: 100%;
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        position: relative;
        input{
            border: none;
            background-color: transparent;
            color: #fff;
            text-decoration: none;
            line-height: 1;
            font-weight: 300;
            font-family: 'HelveticaNeue';
            font-size: 26px;
            @media (max-width: 1599.98px) {
                font-size: 20px;
            }
            @media (max-width: 576.98px) {
                font-size: 17px;
            }
            &:focus{
                outline: none;
                border: none;
                box-shadow: none;
            }
            &::-webkit-input-placeholder { /* Edge */
                color: #fff;
                opacity: 1;
                text-decoration: none;
                line-height: 1.5;
                font-weight: 300;
            }
              
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #fff;
                opacity: 1;
                text-decoration: none;
                line-height: 1.5;
                font-weight: 300;
            }
            
            &::placeholder {
                color: #fff;
                opacity: 1;
                text-decoration: none;
                line-height: 1.5;
                font-weight: 300;
            }
        }
    }
    .map_action_wrapper{
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        @media (max-width: 576.98px) {
            width: 30px;
            height: 30px;
          }
        .map_message_icon{
            position: absolute;
            opacity: 0;
            transition: .3s opacity ease-in-out;
            border: none;
            background-color: transparent;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
            pointer-events: none;
            &.active{
                opacity: .5;
                pointer-events: all;
            }
            &.active:hover{
                opacity: 1;
            }
            span{
                font-size: 33px;
                @media (max-width: 576.98px) {
                  font-size: 20px;
                }
            }
        }
        .map_camera_icon{
            position: absolute;
            opacity: 0;
            transition: .3s opacity ease-in-out;
            border: none;
            background-color: transparent;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
            pointer-events: none;
            &.active{
                opacity: .5;
                pointer-events: all;
                
            }
            &.active:hover{
                opacity: 1;
            }
            span{
                font-size: 33px;
                @media (max-width: 576.98px) {
                    font-size: 20px;
                  }
            }
        }
    }
  }

  
  .search_result_wrapper {
    max-width: 1146px;
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
    position: relative;
    z-index: 1;
    @media (max-width: 1599.98px) {
        max-width: 990px;
    }
    @media (max-width: 1199.98px) {
      max-width: 767px;
    }
    @media (max-width: 1023.98px) {
      max-width: 500px;
        
    }
    .landing_search_result {
      left: 0;
      @media (max-width: 576px) {
        max-width: calc(100% - 30px);
        left: 15px;
          
      }
    }
  }
  .landing_search_result {
    width: 100%;
    /* top: calc(100% + 15px); */
    top: 85px;
     /* left: 20%; */
    z-index: 9;
    position: absolute;
    background-color: #fff;
    max-width: 346px;
    height: auto;
    max-height: 300px;
    border-radius: 15px;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 8px;
    box-shadow: 1px 1px 50px -12px rgba(0, 0, 0, 0.2);
    @media (max-width: 1599.98px) {
      top: 59px !important;
      /* left: 14%; */
    }
    @media (max-width: 576.98px) {
      top: 56px !important;
      /* left: 14%; */
    }
    .landing_search_result_inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        .lading_search_result_item {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            flex-direction: column;
            margin-bottom: 8px;
            border: none;
            -webkit-appearance: none;
            background-color: transparent;
            transition: 0.3s all ease-in-out;
            cursor: pointer;
            &:hover{
                background-color: #F5F5F5;
            }
            .landing_search_item_inner {
                width: 100%;
                display: flex;
                position: relative;
                padding: 6px 16px;
                align-items: center;
                .landing_image_wrapper {
                    width: 36px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    border-radius: 10px;
                    overflow: hidden;
                    .landing_search_icon_container {
                        width: 100%;
                        position: relative;
                        padding-bottom: calc((36 / 36) * 100%);
                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                          }
                      }
                  }
              }
              h3 {
                margin-top: 0;
                margin-bottom: 0;
                font-size: 18px;
                line-height: 1.5;
                color: #222222;
                font-family: "HelveticaNeue";
                font-weight: 400;
                padding-left: 13px;
              }
          }
      }
  }
  @media (max-height: 740px) {
    .landing_search_result {
      height: auto;
      max-height: 200px;
    }
  }
  @media (max-width: 576px) {
    .landing_search_result {
      max-width: 100%;
      top: calc(100% + 5px);
      height: auto;
      max-height: 250px;
    }
  }
  @media (max-width: 1599px) {
    .landing_search_result .landing_search_result_inner .lading_search_result_item .landing_search_item_inner {
      padding: 5px 14px;
    }
  }
  @media (max-width: 1599px) {
    .landing_search_result .landing_search_result_inner .lading_search_result_item h3 {
      font-size: 16px;
    }
  }
  @media (max-width: 576px) {
    .landing_search_result .landing_search_result_inner .lading_search_result_item h3 {
      font-size: 14px;
    }
  }
  