.map_filter_items {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #f4f9ff;
    padding: 10px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    @media (max-width: 1599.98px) {
        padding: 10px 10px;
        margin-bottom: 10px;
    }
    @media (max-width: 576.95px) {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 7px 5px;
        margin-bottom: 15px;
    }
    .select_custom {
        & > div {
            position: relative;
            font-size: 14px;
            line-height: 1.5;
            color: #000;
            text-decoration: none;
            font-weight: 400;
            border-radius: 4px;
            border: 1px solid #e1e4e7;
            background-color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            min-height: 1px;
            & > div {
                padding-top: 0;
                padding-bottom: 0;
                & > div {
                    color: #000;
                }
                & + div {
                    & > div {
                        padding: 0 8px;
                    }
                }
            }
        }
    }
    .cityrange {
        /* margin-bottom: 10px; */
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 767px) {
            width: 100%;
        }
        .mapinfo_select {
            padding-right: 5px;
            flex: 0 0 50% !important;
            max-width: 50% !important;
            & + .mapinfo_select {
                padding-left: 5px;
                padding-right: 0;
            }
        }
    }
    .mapinfo_select,
    .select_custom {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
            @media (max-width: 576.95px) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        @media (max-width: 576.95px) {
            padding: 0px 3px;
            margin-bottom: 0;
            flex-basis: 50%;
            max-width: 50%;
        }
        select {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .css-yk16xz-control {
        }
        input,
        .map_filter_custom_head {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            font-size: 14px;
            line-height: 1.5;
            color: #000;
            text-decoration: none;
            font-weight: 400;
            padding: 4px 8px;
            border-radius: 4px;
            border: 1px solid #e1e4e7;
            background-color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            @media (max-width: 576.98px) {
                padding-right: 17px;
            }
            &::placeholder {
                color: #000;
            }
            &::after {
                content: "";
                position: absolute;
                right: 15px;
                width: 0px;
                height: 0px;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 6px solid #a2a2a2;
                top: 0px;
                bottom: 0;
                margin: auto;
                @media (max-width: 576.98px) {
                    right: 6px;
                }
            }
        }
        .react-datepicker__input_container {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                right: 15px;
                width: 0px;
                height: 0px;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 6px solid #a2a2a2;
                top: 0px;
                bottom: 0;
                margin: auto;
            }
            input::after {
                display: none;
            }
        }
        .map_filter_custom_list {
            position: absolute;
            top: calc(100% + 2px);
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 8px;
            border-radius: 4px;
            border: 1px solid #e1e4e7;
            background-color: #fff;
            max-height: 132px;
            padding-bottom: 22px;
            opacity: 0;
            pointer-events: none;
            transition: 0.3s opacity ease-in-out;
            z-index: 9;
            ul {
                margin: 0px;
                padding: 0px;
                display: flex;
                flex-direction: column;
                position: relative;
                height: 100%;
                overflow: hidden;
                overflow-y: auto;
                li {
                    font-size: 14px;
                    line-height: 1.5;
                    color: #000;
                    font-weight: 400;
                    margin-bottom: 5px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .know_more_button {
                height: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 100%;
                height: 22px;
                bottom: 0;
                left: 0;
                right: 0;
                overflow: hidden;
                z-index: 1;
                button {
                    width: max-content;
                    border: none;
                    background-color: transparent;
                    appearance: none;
                    cursor: pointer;
                }
                span {
                    font-size: 15px;
                }
            }
        }
        &.active {
            .map_filter_custom_list {
                opacity: 1;
                pointer-events: all;
            }
        }
        .customfield {
            border: none;
            & > div {
                min-height: 30px;
                border-color: rgba(226, 226, 226, 1);
                & > div {
                    padding: 0 8px;
                    & > div {
                        font-size: 14px;
                        color: #000;
                        & + div {
                            padding-bottom: 0;
                            padding-top: 0;
                        }
                    }
                    & + div {
                        & > span {
                        }
                        & > div {
                            padding: 0;
                            svg {
                                height: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    .dtrangepick {
        @media (max-width: 576.98px) {
            padding-top: 10px;
            & > div {
                :global(.rdt) {
                    & + :global(.rdt) {
                        padding-top: 5px;
                        padding-right: 0;
                    }
                }
            }
        }
        :global(.rdtPicker) {
            position: relative;
            width: 100%;
            max-width: 100%;
        }
    }
}

.action_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-top: 10px;
    border-top: 1px solid #e8e8e8;
    @media (max-width: 1599.98px) {
        padding-top: 10px;
    }
    @media (max-width: 576.98px) {
        padding-top: 10px;
    }

    .cancel_btn {
        font-size: 14px;
        line-height: 1;
        color: #000;
        font-weight: 400;
        border: none;
        background-color: transparent;
        margin-right: 17px;
        cursor: pointer;
        transition: 0.3s opacity ease-in-out;
        @media (max-width: 1599.98px) {
            font-size: 13px;
        }
        &:hover {
            opacity: 0.5;
        }
        @media (max-width: 576.98px) {
            margin-left: auto;
        }
    }
    .apply_filter_btn {
        font-size: 14px;
        line-height: 1;
        color: #fff;
        border-radius: 4px;
        padding: 6px 8px;
        font-weight: 400;
        background-color: #00a841;
        margin-right: 17px;
        border: none;
        transition: 0.3s background-color ease-in-out;
        cursor: pointer;
        @media (max-width: 1599.98px) {
            font-size: 13px;
        }
        &:hover {
            background-color: #018c36;
        }
        @media (max-width: 576.98px) {
            margin-right: 0px;
        }
    }
}
