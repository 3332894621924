.loading_text{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    z-index: 9999;
    .loading_inner_text{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        padding: 15px;
    }


    .content_wrapper_text{
        width: 100%;
        text-align: center;
        position: relative;
        h2{
            font-size: 70px;
            line-height: 1.3;
            color: #000;
            text-align: center;
            margin: 0;
            font-weight: 500;
            @media (max-width: 1599px) {
                font-size: 60px;
            }
            @media (max-width: 767px) {
                font-size: 40px;
                margin-bottom: 0px;
            }
            @media (max-width: 576px) {
                font-size: 30px;
            }
            span{
                font-weight: 700;
            }
        }
        
    }
}