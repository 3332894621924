body a{
    text-decoration: none ;
}
input:focus,
input:focus-visible,
button:focus{
    outline: none !important;
    box-shadow: none !important;
}
.modal{
    z-index: 9999 !important;
}
.modal-backdrop{
    z-index: 9999 !important;
}
body {
    font-family: "HelveticaNeue" !important;
}


/* Variables */
/* Variables */
/* Common grid start*/
.container {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 480px !important;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px !important;
   }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px !important;
   }
}
@media (min-width: 992px) {
    .container {
        max-width: 932px !important;
   }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px !important;
   }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1320px !important;
   }
}
@media (min-width: 1800px) {
    .container {
        max-width: 1540px !important;
   }
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: -12px;
    margin-left: -12px;
}
.row > * {
    padding-left: 12px;
    padding-right: 12px;
}

/* .btn.btn-primary:focus,
.btn.btn-primary:focus-visible,
.btn.btn-primary:hover{
    background-color: transparent !important;
} */
