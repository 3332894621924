.direction_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    padding: 13px 10px;
    padding-bottom: 0;
  }
  
  
  // config heading 
  .direction_heading_wrapper{
    width: 100%;
    position: relative;
    margin-bottom: 23px;
    padding-bottom: 25px;
    border-bottom: 1px solid #E8E8E8;
    @media (max-width: 1599px) {
      margin-bottom: 20px;
      padding-bottom: 18px;
      }
      h3{
          font-size: 30px;
          line-height: 1.5;
          color: #272727;
          font-weight: 600;
          margin: 0;
          width: 100%;
          text-align: left;
          @media (max-width: 1599px) {
              font-size: 22px;
          }
          @media (max-width: 576px) {
              font-size: 18px;
          }
    }
    span{
      font-size: 20px;
      line-height: 1.5;
      color: #272727;
      font-weight: 400;
      margin: 0;
      width: 100%;
      text-align: left;
      @media (max-width: 1599px) {
          font-size: 16px;
      }
      @media (max-width: 576px) {
          font-size: 14px;
      }
    }
    p{
      font-size: 20px;
      line-height: 1.5;
      color: #272727;
      font-weight: 400;
      margin: 0;
      width: 100%;
      text-align: left;
      @media (max-width: 1599px) {
          font-size: 16px;
      }
      @media (max-width: 576px) {
          font-size: 14px;
      }
    }
  }
  
  
  
  .direction_action_wrapper{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      align-items: center;
      margin-bottom: 30px;
      justify-content: space-between;
      max-width: 453px;
  
    .direction_camera_icon{
      width: calc(50% - 4px);
      display: flex;
      height: 50px;
      border-radius: 3px;
      padding: 0px 25px;
      justify-content: center;
      color: #fff;
      border: none;
      background-color: #0079FF;
      align-items: center;
      font-size: 22px;
      font-weight: 500;
      @media (max-width: 1599px) {
          font-size: 18px;
      }
      @media (max-width: 576px) {
          height: 40px;
          font-size: 16px;
          width: calc(50% - 10px);
      }
      span{
          padding-right: 40px;
          @media (max-width: 1599px) {
              padding-right: 30px;
          }
          @media (max-width: 576px) {
              padding-right: 12px;
          }
      }
      span::before{
          color: #fff;
          font-size: 20px;
          @media (max-width: 576px) {
              font-size: 16px;
          }
      }
    }
    .direction_message_icon{
      width: calc(50% - 4px);
      display: flex;
      height: 50px;
      border-radius: 3px;
      padding: 0px 25px;
      justify-content: center;
      color: #fff;
      border: none;
      background-color: #0079FF;
      align-items: center;
      font-size: 22px;
      font-weight: 500;
      @media (max-width: 1599px) {
          font-size: 18px;
      }
      @media (max-width: 576px) {
          height: 40px;
          font-size: 16px;
          width: calc(50% - 10px);
      }
      span{
          padding-right: 40px;
          @media (max-width: 1599px) {
              padding-right: 30px;
          }
          @media (max-width: 576px) {
              padding-right: 12px;
          }
      }
      span::before{
          color: #fff;
          font-size: 20px;
          @media (max-width: 576px) {
              font-size: 16px;
          }
      }
  }
   
  }
  
  
  .result-header-wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 55px;
      @media (max-width: 576px) {
          margin-bottom: 30px;
      }
      h3{
          font-size: 26px;
          line-height: 1.45;
          text-align: center;
          font-weight: 700;
          width: 100%;
          margin: 0px;
          margin-bottom: 15px;
          @media (max-width: 576px) {
              font-size: 20px;
              margin-bottom: 5px;
          }
      }
      p{
          font-size: 16px;
          line-height: 1.45;
          text-align: center;
          font-weight: 400;
          width: 100%;
          margin: 0px;
          @media (max-width: 576px) {
              font-size: 14px;
          }
      }
  }
  
  
  
  
  
  
  .result_direction_wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      position: relative;
      overflow: hidden;
      overflow-y: auto;
      @media (max-width:1199px) {
          overflow: visible;
          overflow-y: visible;
      }
  
      @media (max-width: 576.98px) {
          overflow: hidden;
          overflow-x: auto;
          overflow-y: hidden;
          display: block;
          white-space: nowrap;
          padding-bottom: 30px;
      }
  
      &::-webkit-scrollbar {
          width: 12px;
      }
      &::-webkit-scrollbar-thumb {
          height: 6px;
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          -webkit-border-radius: 7px;
          background-color: #DDDDDD;
      }
      &::-webkit-scrollbar-button {
          width: 0;
          height: 0;
          display: none;
      }
      &::-webkit-scrollbar-corner {
          background-color: transparent;
      }
  
      .result_direction_item{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          align-items: flex-start;
          margin-bottom: 30px;
          @media (max-width: 576px) {
              width: 96px;
              display: inline-block;
              margin-right: 8px;
              overflow: hidden;
              margin-bottom: 0;
              &:last-child{
                  margin-right: 0;
              }
          }
          &:last-child{
              margin-bottom: 0;
          }
          .result_direction_image{
              width: 100%;
              max-width: 224px;
              position: relative;
              border-radius: 8px;
              overflow: hidden;
              @media (max-width: 1599.98px) {
                  max-width: 155px;
              }
              @media (max-width: 576.98px) {
                  max-width: 96px;
                  margin-bottom: 0px;
                  &::after{
                      content: '';
                      background: rgb(255,255,255);
                      background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                      background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      right: 0;
                      height: 55px;
                  }
              }
              .result_direction_image_container{
                  cursor: pointer;
                  width: 100%;
                  position: relative;
                  padding-bottom: calc((83 / 224) * 100%);
                  @media (max-width: 576.98px) {
                      padding-bottom: calc((96 / 96) * 100%);
                  }
                  img{
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      object-position: center;
                  }
              }
          }
      }
      .result_direction_content{
          width: 100%;
          display: flex;
          flex-direction: column;
          flex: 1;
          padding-left: 17px;
          @media (max-width: 576px) {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              padding: 6px;
              justify-content: flex-end;
          }
          h4{
              width: 100%;
              font-size: 19px;
              line-height: 1.5;
              margin-bottom: 3px;
              margin-top: 0;
              @media (max-width: 1599.98px) {
                  font-size: 16px;
              }
              @media (max-width: 576px) {
                  font-size: 10px;
                  white-space: nowrap;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 0;
              }
          }
          p{
              width: 100%;
              font-size: 14px;
              line-height: 1.5;
              margin-bottom: 6px;
              margin-top: 0;
              @media (max-width: 1599.98px) {
                  margin-bottom: 3px;
              }
              @media (max-width: 576px) {
                  font-size: 8px;
                  white-space: nowrap;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
              }
          }
          a{
              width: max-content;
              @media (max-width: 576px) {
                  position: absolute;
                  opacity: 0;
                  z-index: 1;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
              }
          }
          span::before{
              color: #4043B4;
          }
      }
  }

  .close{
    position: absolute;
    right: 15px;
    top: 15px;
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-size: 24px;
    z-index: 99;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    @media (max-width: 576px) {
        font-size: 20px;
    }
    &:hover{
        opacity: .5;
    }
    button{
        color: inherit;
        background-color: transparent;
        font-weight: inherit;
        border: none;
        font-size: inherit;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
    }
  }

  .read_more_wrapper{
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    @media (max-height: 740px) and (max-width : 1023.98px){
        height: 33px;
    }
    @media (max-width : 576.98px){
       display: none;
    }
    
    .uparrow {
        transform: rotate(180deg);
    }
    button{
        background-color: transparent;
        border: none;
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            font-size: 30px;
        }
    }
}