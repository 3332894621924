.billboard_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    &::after {
        content: "";
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        background: -webkit-linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20%;
        opacity: 0.8;
    }

    .main_head {
        position: absolute;
        left: 50%;
        top: 50px;
        transform: translateX(-50%);
        font-size: 30px;
        line-height: 1.5;
        color: #272727;
        font-weight: 600;
        margin: 0;
        text-align: center;
        width: max-content;
        z-index: 2;
        @media (max-width: 991px) {
            font-size: 24px;
            top: 50px;
        }
        @media (max-width: 576px) {
            font-size: 18px;
            top: 60px;
        }
    }

    .dashboard_action_wrapper {
        position: absolute;
        top: 45px;
        right: 65px;
        width: max-content;
        display: flex;
        z-index: 9991;
        @media (max-width: 767px) {
            right: 15px;
        }
        @media (max-width: 576px) {
            right: 15px;
            top: 20px;
        }
        .filter_wrapper {
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            @media (max-width: 767px) {
                width: 35px;
                height: 35px;
                margin-right: 15px;
            }
            @media (max-width: 576px) {
                margin-right: 5px;
                img {
                    width: auto;
                    height: 23px;
                }
            }
        }
        .action_wrapper {
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media (max-width: 767px) {
                width: 35px;
                height: 35px;
            }
            @media (max-width: 576px) {
                img {
                    width: auto;
                    height: 23px;
                }
            }
        }
    }

    @media (max-width: 576px) {
        .header_wrapper.action_active {
            .logo_wrapper {
                transform: translateX(-20px);
            }
        }
    }
}
