.footer_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    z-index: 99;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 60px;
    height: 50px;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    .footer_wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: space-between;
    }
    .footer_wrapper ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        justify-content: center;
        li {
            width: auto;
            margin-right: auto;
            margin-right: 25px;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 13px;
                line-height: 1.5;
                color: #485550;
                transition: 0.3s color ease-in-out;
                font-weight: 500;
                &:hover {
                    color: #0276F2;
                }
            }
        }
    }
    &.active {
        opacity: 1;
        pointer-events: all;
    }
  }
  @media (max-width: 767px) {
    .footer_main {
      padding: 10px 15px;
      height: auto;
    }
  }
  @media (max-width: 767px) {
    .footer_main .footer_wrapper {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  @media (max-width: 767px) {
    .footer_main .footer_wrapper ul:nth-child(1) {
      margin-bottom: 3px;
    }
  }
  @media (max-width: 767px) {
    .footer_main .footer_wrapper ul li {
      margin-right: 20px;
    }
  }
  