.bg {
    background: #F8F8F8;
    font-family: 'Ubuntu', sans-serif !important;
}

/* Variables */
$white: #FFFFFF;
$black: #000000;
$font: 'Ubuntu', sans-serif !important;
$font-family-alt: 'Roboto', sans-serif !important;
/* Variables */

/* Common grid start*/

.container {
    width: 100%;
    padding-right: .75rem;
    padding-left: .75rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 480px !important;
    @media (min-width: 576px) {
        max-width: 540px !important;
    }
    @media (min-width: 768px) {
        max-width: 720px !important;
    }
    @media (min-width: 992px) {
        max-width: 932px !important;
    }
    @media (min-width: 1200px) {
        max-width: 1140px !important;
    }
    @media (min-width: 1400px) {
        max-width: 1320px !important;
    }
    @media (min-width: 1800px) {
        max-width: 1540px !important;
    }
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: -12px;
    margin-left: -12px;
    &>* {
        padding-left: 12px;
        padding-right: 12px;
    }
}
.cmn_space {
    padding: 90px 0;
}
/* Common grid ends*/



/* Banner starts */
.bnrsec {
    padding: 55px 0;
    background-color: $white;
    position: relative;
    .bnrmapsec {
        position: absolute;
        width: 100%;
        height: 100%;
        /* opacity: .15; */
        top: 0;
        left: 0;
    }
    .container {
        position: relative;
        z-index: 11;
    }
    .bnrwrapper {
        padding-left: 10px;
        padding-right: 10px;
        @media (min-width: 1200px) {
            padding-left: 45px;
            padding-right: 45px;
        }
        .bnrtext {
            
            max-width: 500px;
            @media (min-width: 992px) {
                padding-top: 105px;
            }
            h1 {
                font-weight: 700;
                margin: 0;
                font-size: 38px;
                padding-bottom: 18px;
                @media (min-width: 1200px) {
                    font-size: 40px;
                    padding-bottom: 22px;
                }
                @media (min-width: 1800px) {
                    font-size: 50px;
                    padding-bottom: 30px;
                }
            }
            p {
                font-family: $font-family-alt;
                font-size: 19px;
                @media (min-width: 1200px) {
                    font-size: 21px;
                }
                @media (min-width: 1800px) {
                    font-size: 22px;
                }
            }
        }
        .bnrimgs {
            max-width: 675px;
            display: flex;
            flex-wrap: wrap;
            .bimgcol {
                flex-basis: 33.33%;
                max-width: 33.33%;
                padding: 0 5px;
                @media (min-width: 768px) {
                    padding: 0 10px;
                }
                &:nth-child(1) {
                    padding-top: 50px;
                    @media (min-width: 768px) {
                        padding-top: 70px;
                    }
                }
                &:nth-child(2) {
                    padding-top: 25px;
                    @media (min-width: 768px) {
                        padding-top: 35px;
                    }
                }
                .bimg {
                    margin: 0;
                    max-width: 100%;
                    padding: 5px 0;
                    @media (min-width: 768px) {
                        padding: 10px 0;
                    }
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
/* Banner ends */

.filter_fold {
    padding: 40px 0 35px;
    @media (min-width: 1200px) {
        padding: 79px 0 43px;
    }
    .container {}
    .filtersec {
        .filters {
            .filterbtn {
                font-family: "HelveticaNeue";
                font-weight: 400;
                border: 1px solid #707070;
                border-radius: 12px;
                background-color: $white;
                margin-top: 5px;
                margin-bottom: 5px;
                margin-right: 7px;
                padding: 10px 10px;
                font-size: 17px;
                line-height: 1;
                @media (min-width: 992px) {
                    margin-right: 10px;
                    font-size: 17px;
                    border-radius: 15px;
                }
                @media (min-width: 1800px) {
                    border-radius: 19px;
                    margin-right: 10px;
                    padding: 12px 18px;
                    font-size: 19px;
                }
                img {
                    margin-top: -4.5px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .category_slide {
        position: relative;
        padding-top: 30px;
        @media (min-width: 992px) {
            padding-top: 0px;
            &:before {
                content: '';
                display: block;
                width: 1px;
                height: calc(100% - 40px);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #707070;
                left: 0;
            }
        }
        @media (max-width: 767px) {
            padding-left: 35px;
            padding-right: 35px;
        }
        :global(.slick-arrow) {
            width: 33px;
            height: 33px;
            padding: 0;
            margin: 0;
            background-color: $white;
            border: 1px solid #707070;
            border-radius: 50%;
            display: block;
            z-index: 1;
            top: 41%;
            transition: all ease-in-out 0.3s;
            &:hover {
                opacity: .6;
            }
            @media (min-width: 568px) {
                width: 40px;
                height: 40px;
                top: 47%;
            }
            @media (min-width: 1200px) {
                width: 40px;
                height: 40px;
                top: 41%;
            }
            @media (min-width: 1800px) {
                width: 44px;
                height: 44px;
                top: 41%;
            }
            span {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 9px;
            }
            &::before {
                display: none;
            }
            &.slick-disabled {
                display: none !important;
            }
            &.slick-next {
                right: -20px;
                @media (min-width: 1800px) {
                    right: 0;
                }
                @media (max-width: 767px) {
                    right: 0;
                }
                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.531' height='15.179' viewBox='0 0 8.531 15.179'%3E%3Cpath id='chevron-left' d='M15.117 18.35 9 12.175 15.117 6' transform='translate(-8 -4.586)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
                    transform: rotate(180deg);
                }
            }
            &.slick-prev {
                left: -20px;
                
                @media (max-width: 767px) {
                    left: 0;
                }
                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.531' height='15.179' viewBox='0 0 8.531 15.179'%3E%3Cpath id='chevron-left' d='M15.117 18.35 9 12.175 15.117 6' transform='translate(-8 -4.586)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
                }
            }
        }
        .slide_items {
            padding: 0 5px;
            .item {
                .iconsec {
                    display: block;
                    margin: auto auto 15px;
                    img {
                        display: block;
                        margin: auto;
                    }
                }
                .itemtitle {
                    font-size: 17px;
                    text-align: center;
                    color: #2E2D26;
                    font-family: "HelveticaNeue";
                    font-weight: 400;
                    @media (min-width: 1200px) {
                        font-size: 18px;
                    }
                    @media (min-width: 1800px) {
                        font-size: 19px;
                    }
                }
            }
        }
    }
    .container {
        position: relative;
        &::before {
            content: '';
            display: block;
            width: calc(100% - 24px);
            height: 2px;
            position: absolute;
            bottom: -40px;
            left: 12px;
            background-color: #C6C6C6;
            @media (min-width: 1200px) {
                height: 3px;
            }
        }
    }
}


/* Category List */
.categories {
    padding-top: 50px;
    @media (min-width: 1200px) {
        padding-top: 70px;
    }
    .container {
        .category_slider {
            padding-top: 15px;
            margin-left: -10px;
            margin-right: -10px;
            .slides {
                padding-left: 10px;
                padding-right: 10px;
            }
            @media (min-width: 1200px) {
                padding-top: 28px;
            }
            .row {
                &>* {
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
                @media (min-width: 768px) {
                    margin-left: -20px;
                    margin-right: -20px;
                    &>* {
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }
            .slidecols {
                @media (min-width: 768px) {
                    flex: 0 0 auto;
                    width: 50%;
                }
                @media (min-width: 992px) {
                    width: 33.33333333%;
                }
                @media (min-width: 1400px) {
                    width: 25%;
                }
            }
            .slide_item {
                position: relative;
                background-color: $white;
                border-radius: 22px;
                max-width: 350px;
                margin: auto;
                .imgsec {
                    line-height: 0;
                    display: block;
                    max-width: 100%;
                    margin: auto;
                    img {
                        max-width: 100%;
                        border-radius: 22px 22px 0 0;
                    }
                }
                .detail_sec {
                    padding: 18px 12px 22px;
                    display: inline-flex;
                    @media (min-width: 768px) {
                        padding: 20px 12px 25px;
                    }
                    @media (min-width: 992px) {
                        padding: 25px 12px 30px;
                    }
                    @media (min-width: 1400px) {
                        padding: 40px 12px 49px;
                    }
                    .itemtitle {
                        font-weight: 700;
                        color: #2E2D26;
                        font-size: 18px;
                        @media (min-width: 768px) {
                            font-size: 19px;
                        }
                        @media (min-width: 992px) {
                            font-size: 21px;
                        }
                        @media (min-width: 1400px) {
                            font-size: 24px;
                        }
                    }
                    .price {
                        font-weight: 700;
                        color: #797979;
                        padding: 0 10px;
                        font-size: 18px;
                        @media (min-width: 768px) {
                            font-size: 19px;
                        }
                        @media (min-width: 992px) {
                            font-size: 21px;
                        }
                        @media (min-width: 1400px) {
                            font-size: 24px;
                            padding: 0 15px;
                        }
                    }
                    .dwnld_icon{
                        max-width: 20px;
                        line-height: 1.8;
                        @media (min-width: 768px) {
                            max-width: 25px;
                        }
                        @media (min-width: 1200px) {
                            max-width: 30px;
                        }
                        @media (min-width: 1400px) {
                            line-height: 2;
                        }
                        a {
                            img {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
            
        }
    }
    
    :global(.slick-arrow) {
        width: 30px;
        height: 30px;
        padding: 0;
        margin: 0;
        background-color: $white !important;
        border: 1px solid #707070;
        border-radius: 50%;
        display: block;
        z-index: 1;
        position: absolute;
        transform: none;
        top: -32px;
        bottom: 0;
        right: 20px;
        left: auto;
        transition: all ease-in-out 0.3s;
        &:hover {
            opacity: .6;
        }
        @media (min-width: 568px) {
            width: 30px;
            height: 30px;
        }
        @media (min-width: 768px) {
            top: -41px;
        }
        @media (min-width: 1200px) {
            width: 40px;
            height: 40px;
            top: -43px;
        }
        @media (min-width: 1800px) {
            width: 44px;
            height: 44px;
            top: -45px;
        }
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 6px;
            opacity: 1 !important;
            @media (min-width: 768px) {
                background-size: 7.5px;
            }
            @media (min-width: 1200px) {
                background-size: 9px;
            }
        }
        &:global(.slick-next) {
            margin-left: 10px;
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.531' height='15.179' viewBox='0 0 8.531 15.179'%3E%3Cpath id='chevron-left' d='M15.117 18.35 9 12.175 15.117 6' transform='translate(-8 -4.586)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
                transform: rotate(180deg);
            }
        }
        &:global(.slick-prev) {
            right: 55px;
            @media (min-width: 1200px) {
                right: 74px;
            }
            @media (min-width: 1800px) {
                right: 74px;
            }
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.531' height='15.179' viewBox='0 0 8.531 15.179'%3E%3Cpath id='chevron-left' d='M15.117 18.35 9 12.175 15.117 6' transform='translate(-8 -4.586)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
            }
        }
    }
    .sec_title {
        font-weight: 700;
        color: #2E2D26;
        font-size: 22px;
        @media (min-width: 768px) {
            font-size: 26px;
        }
        @media (min-width: 992px) {
            font-size: 28px;
        }
        @media (min-width: 1400px) {
            font-size: 30px;
        }
    }
    .slide_detail {
        text-align: right;
        font-weight: 700;
        color: #2E2D26;
        font-family: "HelveticaNeue";
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 18px;
        position: relative;
        @media (min-width: 992px) {
            font-size: 19px;
        }
        @media (min-width: 1400px) {
            font-size: 20px;
        }
        .counts {
            padding-right: 15px;
        }
        :global(.slick-arrow) {
            opacity: 0;
            visibility: hidden;
            width: 30px;
            height: 30px;
            padding: 0;
            margin: 0;
            background-color: $white !important;
            border: 1px solid #707070;
            border-radius: 50%;
            display: block;
            z-index: 1;
            position: relative;
            transform: none;
            top: auto;
            right: auto;
            left: auto;
            transition: all ease-in-out 0.3s;
            &:hover {
                opacity: .6;
            }
            @media (min-width: 568px) {
                width: 30px;
                height: 30px;
            }
            @media (min-width: 1200px) {
                width: 40px;
                height: 40px;
            }
            @media (min-width: 1800px) {
                width: 44px;
                height: 44px;
            }
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 6px;
                @media (min-width: 768px) {
                    background-size: 9px;
                }
            }
            &:global(.slick-next) {
                margin-left: 10px;
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.531' height='15.179' viewBox='0 0 8.531 15.179'%3E%3Cpath id='chevron-left' d='M15.117 18.35 9 12.175 15.117 6' transform='translate(-8 -4.586)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
                    transform: rotate(180deg);
                }
            }
            &:global(.slick-prev) {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.531' height='15.179' viewBox='0 0 8.531 15.179'%3E%3Cpath id='chevron-left' d='M15.117 18.35 9 12.175 15.117 6' transform='translate(-8 -4.586)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
                }
            }
        }
    }
}
/* Category List ends*/

/* Realted List */
.related_items {
    padding-top: 25px;
    margin-top: 25px;
    padding-bottom: 45px;
    @media (min-width: 1200px) {
        padding-top: 30px;
        margin-top: 25px;
        padding-bottom: 68px;
    }
    .container {
        position: relative;
        &::before {
            content: '';
            display: block;
            width: calc(100% - 24px);
            height: 2px;
            position: absolute;
            top: -30px;
            left: 12px;
            background-color: #C6C6C6;
            @media (min-width: 1200px) {
                height: 3px;
            }
        }
        .row {
            &>* {
                padding-top: 12px;
                padding-bottom: 12px;
            }
            @media (min-width: 768px) {
                margin-left: -15px;
                margin-right: -15px;
                &>* {
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            }
        }
    }
    .single_item {
        position: relative;
        background-color: $white;
        border-radius: 22px;
        .imgsec {
            line-height: 0;
            display: block;
            max-width: 100%;
            margin: auto;
            img {
                max-width: 100%;
                border-radius: 22px 22px 0 0;
            }
        }
        .detail_sec {
            padding: 18px 12px 22px;
            display: flex;
            justify-content: space-between;
            @media (min-width: 768px) {
                padding: 20px 12px 25px;
            }
            @media (min-width: 992px) {
                padding: 25px 12px 30px;
            }
            @media (min-width: 1400px) {
                padding: 40px 12px 49px;
            }
            .itemtitle {
                font-weight: 700;
                color: #2E2D26;
                font-size: 18px;
                @media (min-width: 768px) {
                    font-size: 19px;
                }
                @media (min-width: 992px) {
                    font-size: 21px;
                }
                @media (min-width: 1400px) {
                    font-size: 24px;
                }
            }
            .price {
                font-weight: 700;
                color: #797979;
                padding: 0 10px;
                font-size: 18px;
                @media (min-width: 768px) {
                    font-size: 19px;
                }
                @media (min-width: 992px) {
                    font-size: 21px;
                }
                @media (min-width: 1400px) {
                    font-size: 24px;
                    padding: 0 15px;
                }
            }
            .dwnld_icon{
                max-width: 20px;
                line-height: 1.8;
                @media (min-width: 768px) {
                    max-width: 25px;
                }
                @media (min-width: 1200px) {
                    max-width: 30px;
                }
                @media (min-width: 1400px) {
                    line-height: 2;
                }
                a {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
/* Realted List ends*/


