.billboard_inner {
  width: 100%;
  background-color: #fff;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
  max-width: calc(100% - 130px);
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  max-height: calc(100% - 160px);
  overflow: hidden;
  overflow-y: auto;
  @media (max-width: 767px) {
    max-width: calc(100% - 30px);
  }
  @media (max-width: 576px) {
    max-height: calc(100% - 110px);
  }
}

.scroll_demo {
  height: 152px;
  overflow: scroll;
}
.billboard_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px 55px;
  padding-bottom: 70px;
  @media (max-width: 991px) {
    padding: 20px 30px;
    padding-bottom: 60px;
  }
  @media (max-width: 767px) {
    padding: 15px;
    padding-bottom: 40px;
  }
}

.read_more_wrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  max-width: calc(100% - 130px);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    max-width: calc(100% - 30px);
  }
  @media (max-width: 576px) {
    max-height: calc(100% - 110px);
    height: 30px;
  }
  button {
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: #fff;
    font-size: 0;
    border: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43.703' height='16.168' viewBox='0 0 43.703 16.168'%3E%3Cpath id='chevron-left' d='M18.13,41.627,9,23.814,18.13,6' transform='translate(-1.962 22.168) rotate(-90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='6'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    @media (max-width: 576px) {
      width: 20px;
      height: 20px;
    }
  }
}

.billboard_head {
  width: 100%;
  display: flex;
  justify-content: center;
  h4 {
    font-size: 46px;
    line-height: 1.5;
    color: #272727;
    font-weight: 600;
    margin: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    @media (max-width: 1599px) {
      font-size: 40px;
    }
    @media (max-width: 1199px) {
      font-size: 35px;
      margin-bottom: 20px;
    }
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }

  //   .back_to_page{
  //     width: 45px;
  //     height: 45px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     position: absolute;
  //     top: -1%;
  //     left: -35%;
  //     z-index: 9;
  //     cursor: pointer;
  //     transition: .3s all ease-in-out;
  //     &:hover{
  //         opacity: .5;
  //     }
  //     @media (max-width: 991px) {
  //         top: -1%;
  //         left: -15%;
  //     }
  //     @media (max-width: 767px) {
  //         top: -1%;
  //         left: 3%;
  //     }
  //     button{
  //         width: 18px;
  //         height: 25px;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.59' height='32.18' viewBox='0 0 17.59 32.18'%3E%3Cpath id='chevron-left' d='M22.969,33.938,9,19.969,22.969,6' transform='translate(-7.5 -3.879)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E%0A");
  //         font-size: 0;
  //         line-height: 0;
  //         background-position: center;
  //         background-size: contain;
  //         background-repeat: no-repeat;
  //         background-color: transparent;
  //         border: none;
  //         @media (max-width: 576px) {
  //             width: 11px;
  //             height: 18px;
  //         }
  //     }
  // }

  .back_btn {
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 19px;
    pointer-events: all;

    z-index: 99;

    @media (max-width: 767px) {
      top: 17px;
      left: 10px;
    }
    button {
      border: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 25px;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.59' height='32.18' viewBox='0 0 17.59 32.18'%3E%3Cpath id='chevron-left' d='M22.969,33.938,9,19.969,22.969,6' transform='translate(-7.5 -3.879)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E%0A");
      font-size: 0;
      line-height: 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
      @media (max-width: 576px) {
        width: 11px;
        height: 18px;
      }
      /*  &::after{
            content: '';
            position: absolute;
            display: block;
            width: 15px;
            height: 15px;
            border-left: 2px solid #000000;
            border-top: 2px solid #000000;
            transform: rotate(-45deg);
        } */
    }
  }
}

.result_wrapper_billboard {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.36);
  padding-bottom: 24px;
  margin-bottom: 30px;
  @media (max-width: 576px) {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .result_inner {
    display: flex;
    position: relative;
    .result_item {
      width: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      &::after {
        width: 2px;
        height: 16px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #000;
        content: "";
        border-radius: 2px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      .result_item_inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0px 30px;
        @media (max-width: 576px) {
          padding: 0px 10px;
        }
        .result_count {
          font-size: 40px;
          line-height: 1.5;
          color: #3e8ff3;
          font-weight: 600;
          margin: 0;
          text-align: center;
          margin-bottom: 5px;
          @media (max-width: 1599px) {
            font-size: 35px;
          }
          @media (max-width: 1199px) {
            font-size: 30px;
          }
          @media (max-width: 576px) {
            font-size: 20px;
          }
        }
        p {
          font-size: 23px;
          line-height: 1.5;
          color: #000;
          font-weight: 400;
          margin: 0;
          text-align: center;
          @media (max-width: 1599px) {
            font-size: 20px;
          }
          @media (max-width: 1199px) {
            font-size: 16px;
          }
          @media (max-width: 576px) {
            font-size: 11px;
          }
        }
      }
    }
  }
}

.board_billboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 54px;
  padding-right: 54px;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 576px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  .board_result_inner {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0px -38px;
    @media (max-width: 1599px) {
      margin: 0px -25px;
    }
    @media (max-width: 1366px) {
      margin: 0px -18px;
    }
    @media (max-width: 767px) {
      margin: 0px -10px;
    }
    @media (max-width: 767px) {
      margin: 0px -4px;
    }
    .board_result_item {
      width: 20%;
      display: flex;
      cursor: pointer;
      flex-direction: column;
      position: relative;
      padding: 10px 38px;
      @media (max-width: 1599px) {
        padding: 10px 25px;
      }
      @media (max-width: 1366px) {
        padding: 10px 18px;
        width: 25%;
      }
      @media (max-width: 1199px) {
        width: 25%;
      }
      @media (max-width: 1023px) {
        width: 33.333%;
      }
      @media (max-width: 767px) {
        width: 50%;
        padding: 5px 10px;
      }
      @media (max-width: 576px) {
        width: 50%;
        padding: 4px 4px;
        margin-bottom: 4px;
      }

      .board_result_item_inner {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-decoration: none;
        .image_wrapper {
          width: 100%;
          overflow: hidden;
          border-radius: 8px;
          margin-bottom: 8px;
          @media (max-width: 576px) {
            margin-bottom: 5px;
          }
          .image_container {
            width: 100%;
            position: relative;
            padding-bottom: calc((92 / 252) * 100%);
            img {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }

        .content_wrapper {
          width: 100%;
          max-width: calc(100% - 25px);
          display: flex;
          flex-direction: column;
          position: relative;
        }
        .map_wrapper {
          width: 25px;
          height: 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            font-size: 24px;
            @media (max-width: 576px) {
              font-size: 15px;
            }
            &::before {
              color: #4043b4;
            }
          }
        }
      }

      h5 {
        margin: 0px;
        position: relative;
        font-size: 18px;
        line-height: 1.3;
        color: #272727;
        font-weight: 600;
        margin-bottom: 2px;
        @media (max-width: 1599px) {
          font-size: 16px;
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
      p {
        margin: 0px;
        position: relative;
        font-size: 14px;
        line-height: 1.2;
        color: #272727;
        font-weight: 400;
        @media (max-width: 1599px) {
          font-size: 13px;
        }
        @media (max-width: 576px) {
          font-size: 10px;
        }
      }
    }
  }
}

.board_billboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 54px;
  padding-right: 54px;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 576px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  .board_result_inner {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0px -38px;
    @media (max-width: 1599px) {
      margin: 0px -25px;
    }
    @media (max-width: 1366px) {
      margin: 0px -18px;
    }
    @media (max-width: 767px) {
      margin: 0px -10px;
    }
    @media (max-width: 767px) {
      margin: 0px -4px;
    }
    .board_result_item {
      width: 20%;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 10px 38px;
      @media (max-width: 1599px) {
        padding: 10px 25px;
      }
      @media (max-width: 1366px) {
        padding: 10px 18px;
        width: 25%;
      }
      @media (max-width: 1199px) {
        width: 25%;
      }
      @media (max-width: 1023px) {
        width: 33.333%;
      }
      @media (max-width: 767px) {
        width: 50%;
        padding: 5px 10px;
      }
      @media (max-width: 576px) {
        width: 50%;
        padding: 4px 4px;
        margin-bottom: 4px;
      }

      .board_result_item_inner {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-decoration: none;
        .image_wrapper {
          width: 100%;
          overflow: hidden;
          border-radius: 8px;
          margin-bottom: 8px;
          @media (max-width: 576px) {
            margin-bottom: 5px;
          }
          .image_container {
            width: 100%;
            position: relative;
            padding-bottom: calc((92 / 252) * 100%);
            img {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }

        .content_wrapper {
          width: 100%;
          max-width: calc(100% - 25px);
          display: flex;
          flex-direction: column;
          position: relative;
        }
        .map_wrapper {
          width: 25px;
          height: 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            font-size: 24px;
            @media (max-width: 576px) {
              font-size: 15px;
            }
            &::before {
              color: #4043b4;
            }
          }
        }
      }

      h5 {
        margin: 0px;
        position: relative;
        font-size: 18px;
        line-height: 1.3;
        color: #272727;
        font-weight: 600;
        margin-bottom: 2px;
        @media (max-width: 1599px) {
          font-size: 15px;
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
      p {
        margin: 0px;
        position: relative;
        font-size: 14px;
        line-height: 1.2;
        color: #272727;
        font-weight: 400;
        @media (max-width: 1599px) {
          font-size: 13px;
        }
        @media (max-width: 576px) {
          font-size: 10px;
        }
      }
    }
  }
}

.no__results {
  text-align: center;
  color: red;
}
