.billboard_wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  &::after{
    content: '';
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    opacity: .8;
}
  .billboard_inner{
    width: 100%;
    background-color: #fff;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
    max-width: calc(100% - 130px);
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    max-height: calc(100% - 160px);
    overflow: hidden;
    overflow-y: auto;
    @media (max-width: 767px) {
      max-width: calc(100% - 30px);
    }
    @media (max-width: 576px) {
      max-height: calc(100% - 110px);
    }
  }
}

.billboard_main{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px 55px;
  padding-bottom: 70px;
  @media (max-width: 991px) {
    padding: 20px 30px;
    padding-bottom: 60px;
  }
  @media (max-width: 767px) {
    padding: 15px;
    padding-bottom: 40px;
  }
  
}
.read_more_wrapper{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  max-width: calc(100% - 130px);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    max-width: calc(100% - 30px);
  }
  @media (max-width: 576px) {
    max-height: calc(100% - 110px);
    height: 30px;
  }
  button{
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: #fff;
    font-size: 0;
    border: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43.703' height='16.168' viewBox='0 0 43.703 16.168'%3E%3Cpath id='chevron-left' d='M18.13,41.627,9,23.814,18.13,6' transform='translate(-1.962 22.168) rotate(-90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='6'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 576px) {
      width: 20px;
      height: 20px;
    }
  }
}

.billboard_head{
  width: 100%;
  display: flex;
  justify-content: center;
  h4{
    font-size: 46px;
    line-height: 1;
    color: #272727;
    font-weight: 600;
    margin: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    @media (max-width: 1599px) {
      font-size: 40px;
    }
    @media (max-width: 1199px) {
      font-size: 35px;
      margin-bottom: 20px;
    }
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }
}

.billboard_progressive_bar{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1108px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  @media (max-width: 1199px) {
    margin-bottom: 25px;
  }
  @media (max-width: 576px) {
    margin-bottom: 15px;
  }
}


.progressive_bar_head{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  max-width: calc(100% - 225px);
  padding-right: 60px;
  margin-bottom: 7px;
  @media (max-width: 1199px) {
    padding-right: 40px;
  }
  @media (max-width: 1199px) {
    max-width: calc(100% - 150px);
  }
  @media (max-width: 576px) {
    max-width: 100%;
    padding-right: 0;
  }
  .price_end,
  .price_start{
    width: auto;
    font-size: 30px;
    line-height: 1;
    color: #272727;
    font-weight: 600;
    margin: 0;
    text-align: center;
    @media (max-width: 1599px) {
      font-size: 28px;
    }
    @media (max-width: 1199px) {
      font-size:22px;
    }
  }
  .price_update{
    width: auto;
    font-size: 45px;
    line-height: 1;
    color: #272727;
    font-weight: 600;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
    @media (max-width: 1599px) {
      font-size: 42px;
    }
    @media (max-width: 1199px) {
      font-size: 30px;
    }
  }
}


.progressivebar_with_btn{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  @media (max-width: 576px) {
    max-width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
  .progressivebar_main{
    width: 100%;
    max-width: calc(100% - 225px);
    padding-right: 60px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: 1199px) {
      padding-right: 40px;
    }
    @media (max-width: 1199px) {
      max-width: calc(100% - 150px);
    }
    @media (max-width: 576px) {
      max-width: 100%;
      padding-right: 0;
    }
    .progressivebar_track{
      width: 100%;
      height: 21px;
      background-color: #F1F1F1;
      border-radius: 21px;
      position: relative;
      @media (max-width: 1199px) {
        height: 12px;
      }
      @media (max-width: 576px) {
        height: 9px;
      }
      .progressivebar_track_update{
        width: 60%;
        display: flex;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #257BE5;
        border-radius: 21px;
        position: relative;
        
        .drag_switch{
          width: 64px;
          height: 64px;
          position: absolute;
          right: 0px;
          top: 50%;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 1px 1px 20px -5px rgba(0, 0, 0, 0.2);
          transform: translateY(-50%);
          @media (max-width: 1199px) {
            width: 40px;
            height: 40px;
          }
          @media (max-width: 576px) {
            width: 27px;
            height: 27px;
          }
        }
      }
    }
    :global(.rangeslider-horizontal .rangeslider__fill){
      background-color: #257BE5;
    }
    :global(.rangeslider  ){
      box-shadow: none;
      background-color: #F1F1F1;
      margin: 0px;
    }
    :global(.rangeslider-horizontal){
      height: 20px;
      @media (max-width: 1199px) {
        height: 12px;
      }
      @media (max-width: 576px) {
        height: 9px;
      }
    }
    :global(.rangeslider__handle){
        width: 64px;
        height: 64px;
        position: absolute;
        right: 0px;
        top: 50%;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 1px 1px 20px -5px rgba(0, 0, 0, 0.2);
        transform: translateY(-50%) translateX(-30px);
        border: none;
        &::after{
          display: none;
          & > div{
            display: none;
          }
        }
        @media (max-width: 1199px) {
          width: 40px;
          height: 40px;
        }
        @media (max-width: 576px) {
          width: 27px;
          height: 27px;
          transform: translateY(-50%) translateX(-10px);
        }
    }
  }

  .btn_wrapper{
    width: 100%;
    max-width: 225px;
    @media (max-width: 1199px) {
      max-width: 150px;
    }
    @media (max-width: 576px) {
      margin-top: 40px;
    }
    button{
      width: 100%;
      border-radius: 3px;
      background-color: #4EAB4B;
      color: #FFFFFF;
      border: 1px solid #469A43;
      font-weight: 600;
      text-align: center;
      height: 50px;
      font-size: 19px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s all ease-in-out;
      &:hover{
        background-color: transparent;
        color: #4EAB4B;
      }
      @media (max-width: 1199px) {
        font-size: 16px;
        height: 45px;
      }
    }
  }
}



.result_wrapper_billboard{
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.36);
  padding-bottom: 30px;
  margin-bottom: 30px;
  @media (max-width: 576px) {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .result_inner{
    display: flex;
    position: relative;
    .result_item{
      width: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      &::after{
        width: 2px;
        height: 16px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #000;
        content: '';
        border-radius: 2px;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
      .result_item_inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0px 30px;
        @media (max-width: 576px) {
          padding: 0px 10px;
        }
        .result_count{
          font-size: 42px;
          line-height: 1.2;
          color: #3E8FF3;
          font-weight: 600;
          margin: 0;
          text-align: center;
          margin-bottom: 5px;
          @media (max-width: 1199px) {
            font-size: 30px;
          }
          @media (max-width: 576px) {
            font-size: 20px;
          }
        }
        p{
          font-size: 23px;
          line-height: 1.5;
          color: #000;
          font-weight: 400;
          margin: 0;
          text-align: center;
          @media (max-width: 1199px) {
            font-size: 16px;
          }
          @media (max-width: 576px) {
            font-size: 11px;
          }
        }
      }
    }
  }
}



.board_billboard{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 54px;
  padding-right: 54px;
  @media (max-width : 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width : 576px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  .board_result_inner{
    width: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0px -38px;
    @media (max-width: 1599px) {
      margin: 0px -25px;
    }
    @media (max-width: 1366px) {
      margin: 0px -18px;
    }
    @media (max-width: 767px) {
      margin: 0px -10px;
    }
    @media (max-width: 767px) {
      margin: 0px -4px;
    }
    .board_result_item{
      width: 20%;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 10px 38px;
      @media (max-width: 1599px) {
        padding: 10px 25px;
      }
      @media (max-width: 1366px) {
        padding: 10px 18px;
        width: 25%;
      }
      @media (max-width : 1199px) {
        width: 25%;
      }
      @media (max-width : 1023px) {
        width: 33.333%;
      }
      @media (max-width: 767px) {
        width: 50%;
        padding: 5px 10px;
      }
      @media (max-width: 576px) {
        width: 50%;
        padding: 4px 4px;
        margin-bottom: 4px;
      }
      
      
      .board_result_item_inner{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .image_wrapper{
          width: 100%;
          overflow: hidden;
          border-radius: 8px;
          margin-bottom: 5px;
          @media (max-width: 576px) {
            margin-bottom: 5px;
          }
          .image_container{
            width: 100%;
            position: relative;
            padding-bottom: calc((92 / 252) * 100%);
            img{
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }

        .content_wrapper{
          width: 100%;
          max-width: calc(100% - 25px);
          display: flex;
          flex-direction: column;
          position: relative;
        }
        .map_wrapper{
          width: 25px;
          height: 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span{
            font-size: 24px;
            @media (max-width: 576px) {
              font-size: 15px;
            }
            &::before{
              color: #4043B4;
            }
          }
        }


        
        
      }

      h5{
        margin: 0px;
        position: relative;
        font-size: 18px;
        line-height: 1.3;
        color: #272727;
        font-weight: 600;
        margin-bottom: 2px;
        @media (max-width: 1599px) {
          font-size: 16px;
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
      p{
        margin: 0px;
        position: relative;
        font-size: 14px;
        line-height: 1.2;
        color: #272727;
        font-weight: 400;
        @media (max-width: 1599px) {
          font-size: 13px;
        }
        @media (max-width: 576px) {
          font-size: 10px;
        }
      }

    }
  }
}

.dashboard_action_wrapper{
  position: absolute;
  top: 45px;
  right: 65px;
  width: max-content;
  display: flex;
  z-index: 9991;
  @media (max-width: 767px) {
    right: 15px;
  }
  @media (max-width:576px) {
    right: 15px;
    top: 20px;
  }
  .filter_wrapper{
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 35px;
      height: 35px;
      margin-right: 15px;
      
      
    }
    @media (max-width: 576px) {
      margin-right: 5px;
      img{
        width: auto;
        height: 23px;
      }
    }
  }
  .action_wrapper{
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 35px;
      height: 35px;
    }
    @media (max-width: 576px) {
      img{
        width: auto;
        height: 23px;
      }
    }
  }

  .action_items{
    position: absolute;
    top: 100%;
    background-color: #FAFCFF;
    padding: 30px 22px;
    border-radius: 10px;
    border: 1px solid #B9B9B9;
    width: 181px;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: .3s all ease-in-out;
    &.active{
      opacity: 1;
      pointer-events: all;
    }
    h3{
      font-size: 18px;
      line-height: 1;
      color: #525252;
      margin: 0;
      margin-bottom: 15px;
    }
    ul{
      width: 100%;
      padding: 0;
      margin: 0;
      li{
        width: 100%;
        position: relative;
        margin-bottom: 5px;
        &:last-child{
          margin-bottom: 0;
        }
        button{
          font-size: 14px;
          line-height: 1;
          padding: 0;
          margin: 0;
          border: none;
          font-weight: 400;
          text-align: left;
          background-color: transparent;
          color: #2F3838;
        }
      }
    }
  }
}


@media (max-width: 576px) {
  .header_wrapper.action_active{
    .logo_wrapper{
      transform: translateX(-20px);
    }
  }
}

.main-head{
  position: absolute;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  font-size: 30px;
  line-height: 1.5;
  color: #272727;
  font-weight: 600;
  margin: 0;
  text-align: center;
  width: max-content;
  z-index: 1;
  @media (max-width: 991px) {
    font-size: 24px;
    top: 117px;
  }
  @media (max-width: 576px) {
    font-size: 18px;
    top: 70px;
  }
}