$font-family: "HelveticaNeue";
/* Variables */
$white: #FFFFFF;
$black: #000000;
$font: 'Ubuntu', sans-serif !important;
$font-family-alt: 'Roboto', sans-serif !important;
/* Variables */

.pagecntsec {
    .secwrapper {
        .sectitle {
            font-weight: 700;
            margin: 0;
            font-size: 34px;
            padding-bottom: 20px;
            font-family: $font;
            @media (min-width: 1200px) {
                font-size: 40px;
                padding-bottom: 30px;
            }
            @media (min-width: 1800px) {
                font-size: 50px;
                padding-bottom: 40px;
            }
        }
        .titlewrap {
            max-width: 635px;
        }
    }
    p,li {
        color: #08150A;
        opacity: .5;
        font-size: 17.5px;
        line-height: 1.3;
        font-family: $font-family-alt;
        @media (min-width: 768px) {
            font-size: 19px;
        }
        @media (min-width:1200px) {
            font-size: 20px;
            line-height: 1.2;
        }
    }
    .videoimgs {
        display: flex;
        flex-wrap: wrap;
        max-width: 1050px;
        padding: 44px 0 30px;
        @media (min-width: 768px) {
            padding: 54px 0 30px;
        }
        @media (min-width: 1200px) {
            padding: 74px 0 35px;
        }
        .videowrapper {
            position: relative;
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;
            @media (min-width: 768px) {
                max-width: 63%;
                flex-basis: 63%;
            }
            .usecase_videosec {
                padding-bottom: calc((367 / 658) * 100%);
                max-width: 658px;
                position: relative;
                margin: auto;
                @media (min-width: 1200px) {
                    padding-bottom: calc((367 / 658) * 100%);
                }
                .video {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: #cfcfcf;
                }
                .videoposter {
                    margin: auto;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    cursor: pointer;
                    background-color: rgba($black,0);
                    transition: .5s background-color ease-in-out;
                    &:hover {
                        background-color: rgba($black,.3);
                        .paybtn {
                            background-color: rgba($white,.8);
                        }
                    }
                    .paybtn {
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        background-color: rgba($white,.72);
                        transition: .3s background-color ease-in-out;
                        border: none;
                        outline: none;
                        width: 75px;
                        height: 75px;
                        @media (min-width: 992px) {
                            width: 85px;
                            height: 85px;
                        }
                        @media (min-width: 1200px) {
                            width: 110px;
                            height: 110px;
                        }
                        @media (min-width: 1800px) {
                            width: 150px;
                            height: 150px;
                        }
                        &::before {
                            content: '';
                            display: block;
                            border-radius: 50%;
                            background-color: #E33277;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            width: 48px;
                            height: 48px;
                            @media (min-width: 768px) {
                                width: 55px;
                                height: 55px;
                            }
                            @media (min-width: 1200px) {
                                width: 62px;
                                height: 62px;
                            }
                            @media (min-width: 1800px) {
                                width: 87px;
                                height: 87px;
                            }
                        }
                        &::after {
                            content: '';
                            display: block;
                            width: 0; 
                            height: 0; 
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            border-top: 10px solid transparent;
                            border-bottom: 10px solid transparent;
                            
                            border-left: 18px solid $white;
                            @media (min-width: 768px) {
                                border-top-width: 10px;
                                border-bottom-width: 10px;
                                
                                border-left-width: 18px;
                            }
                            @media (min-width-width: 1200px) {
                                border-top-width: 12px;
                                border-bottom-width: 12px;
                                
                                border-left: 25px;
                            }
                            @media (min-width: 1800px) {
                                border-top-width: 17px;
                                border-bottom-width: 17px;
                                
                                border-left-width: 29px;
                            }
                        }
                    }
                    &:hover {
                        img {
                            opacity: .7;
                        }
                        .paybtn {
                            background-color: rgba($white,.8);
                        }
                    }
                }
            }
        }
        .imgswrapper {
            max-width: 100%;
            flex-basis: 100%;
            @media (min-width: 768px) {
                max-width: 35%;
                flex-basis: 35%;
                padding-left: 24px;
            }
            @media (max-width: 767px) {
                padding-top: 12px;
                display: flex;
                flex-grow: 1;
            }
            .imgsec {
                margin-bottom: 0;
                @media (min-width: 768px) {
                    padding-bottom: 11px;
                }
                @media (max-width: 767px) {
                    padding-right: 6px;
                }
                img {
                    max-width: 100%;
                }
                &+.imgsec {
                    @media (min-width: 768px) {
                        padding-top: 11px;
                        padding-bottom: 0;
                    }
                    @media (max-width: 767px) {
                        padding-right: 0;
                        padding-left: 6px;
                    }
                }
            }
        }
    }
    .relatedimgs {
        display: inline-flex;
        margin-left: -6px;
        margin-right: -6px;
        padding-top: 30px;
        padding-bottom: 50px;
        @media (min-width: 1200px) {
            margin-left: -12px;
            margin-right: -12px;
            padding-top: 40px;
            padding-bottom: 70px;
        }
        @media (min-width: 1800px) {
            margin-left: -20px;
            margin-right: -20px;
        }
        .imgsec {
            padding-left: 6px;
            padding-right: 6px;
            @media (min-width: 1200px) {
                padding-left: 12px;
                padding-right: 12px;
            }
            @media (min-width: 1800px) {
                padding-left: 20px;
                padding-right: 20px;
            }
            img {
                max-width: 100%;
            }
        }
    }
}