.config_wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  padding: 13px 10px;
}


// config heading 
.heading_wrapper{
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  h3{
    font-size: 28px;
    line-height: 1.5;
    color: #272727;
    font-weight: 600;
    margin: 0;
    width: 100%;
    text-align: center;
  }
}


// config name 
.config_name{
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
  .input_list{
    width: 100%;
    flex: 1;
    flex-direction: column;
    position: relative;
    .config_input{
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .config_input{
    width: 100%;
    flex: 1;
    padding-right: 35px;
    position: relative;
    @media (max-width: 576px) {
      padding-right: 15px;
    }
    input{
      width: 100%;
      position: relative;
      display: block;
      border: 1px solid #D5D5D5;
      border-radius: 5px;
      height: 42px;
      padding: 10px ;
      &:focus,
      &:focus-visible{
        outline: none;
        box-shadow: none;
      }
    }
    label{
      font-size: 16px;
      line-height: 1;
      color: #3C3C3C;
      font-weight: 400;
      background-color: #fff;
      position: absolute;
      z-index: 1;
      padding: 0px 7px;
      left: 16px;
      top: -10px;
      span{
        color: #FF0000;
      }
    }
  }
  .config_btn{
    width: 29px;
    height: 29px;
    border-radius: 2px;
    background-color: #0079FF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    appearance: none;
    margin-top: 4px;
    span{
      width: 9px;
      height: 9px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &::after{
        width: 10px;
        height: 2px;
        background-color: #fff;
        content: '';
        position: absolute;
        display: block;
        border-radius: 1px;
      }
      &::before{
        width: 2px;
        height: 10px;
        background-color: #fff;
        content: '';
        position: absolute;
        display: block;
        border-radius: 1px;
      }
    }
  }
  .config_btn_del{
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: #0079FF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    appearance: none;
    border-radius: 50%;
    position: absolute;
    right: 26px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    transition: .3s transform ease-in-out;
    &:hover{
      transform: translateY(-50%) rotate(45deg) scale(1.05);
    }
    @media (max-width: 576.98px) {
      right: 6px;
    }
    span{
      width: 9px;
      height: 9px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &::after{
        width: 10px;
        height: 2px;
        background-color: #fff;
        content: '';
        position: absolute;
        display: block;
        border-radius: 1px;
      }
      &::before{
        width: 2px;
        height: 10px;
        background-color: #fff;
        content: '';
        position: absolute;
        display: block;
        border-radius: 1px;
      }
    }
  }
}


.config_job{
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 35px;
  .config_input{
    width: 100%;
    position: relative;
    textarea{
      width: 100%;
      position: relative;
      border: 1px solid #D5D5D5;
      border-radius: 5px;
      min-height: 98px;
      padding: 10px;
      display: block;
      resize: none;
      &:focus,
      &:focus-visible{
        outline: none;
        box-shadow: none;
      }
    }
    label{
      font-size: 16px;
      line-height: 1;
      color: #3C3C3C;
      font-weight: 400;
      background-color: #fff;
      position: absolute;
      z-index: 1;
      padding: 0px 7px;
      left: 16px;
      top: -10px;
      span{
        color: #FF0000;
      }
    }
  }
}




.search_us{
  width: 100%;
  position: relative;
  padding-bottom: 25px;
  :global(.accordion-item){
    border: none;
  }
  .search_inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      :global(.accordion-body) {
        padding: 0;
      }
      :global(.accordion-button){
        padding: 0px;
        border: none;
        background-color: transparent;
        box-shadow: none;
      }
      :global(.accordion-button){
        padding: 0px;
        border: none;
        background-color: transparent;
        box-shadow: none;
      }
      :global(.accordion-button::after){
        display: none !important;
      }

    .search_heading{
      width: 100%;
      text-align: left;
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin-bottom: 18px;
      h3{
        width: 100%;
        flex: 1;
        position: relative;
        font-size: 18px;
        line-height: 1.5;
        color: #00102C;
        font-weight: 600;
        margin: 0;
        padding-right: 20px;
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }
      .collapse_icon{
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-left: auto;
        border: none;
        background-color: transparent;
        font-size: 0;
        top: 5px;
        @media (max-width: 576px) {
          top: 0px;
        }
        &::after{
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.34' height='8.67' viewBox='0 0 15.34 8.67'%3E%3Cpath id='chevron-left' d='M6.256,12.511,0,6.256,6.256,0' transform='translate(1.414 7.67) rotate(-90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
          content: '';
          position: absolute;
          width: 15px;
          height: 9px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .collapse_wrapper{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 398px;
      .image_wrapper{
        width: auto;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0px -20px;
        @media (max-width: 1599px) {
          margin: 0px -15px;
        }
        @media (max-width: 576px) {
          margin: 0px -8px;
        }
        .image_item{
          width: 33.333%;
          padding: 10px 20px;
          display: flex;
          flex-direction: column;
          position: relative;
          @media (max-width: 1599px) {
            padding: 10px 15px;
          }
          @media (max-width: 576px) {
            padding: 10px 8px;
          }
          .image_inner{
            width: 100%;
            position: relative;
            border: 1px solid #707070;
            border-radius: 10px;
            overflow: hidden;
            .image_container{
              width: 100%;
              position: relative;
              padding-bottom: calc((104 / 104) * 100%);
              img{
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
              }
              input{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
                  
            }
            }
          }
        }
      }
    }
  }
}




.annotation_us{
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  :global(.accordion-item){
    border: none;
  }
  .annotation_inner{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    :global(.accordion-body) {
      padding: 0;
    }
    :global(.accordion-button){
      padding: 0px;
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
    :global(.accordion-button){
      padding: 0px;
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
    :global(.accordion-button::after){
      display: none !important;
    }
    .annotation_heading{
      width: 100%;
      text-align: left;
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin-bottom: 18px;
      h3{
        width: 100%;
        flex: 1;
        position: relative;
        font-size: 18px;
        line-height: 1.5;
        color: #00102C;
        font-weight: 600;
        margin: 0;
        padding-right: 20px;
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }
      .collapse_icon{
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-left: auto;
        border: none;
        background-color: transparent;
        font-size: 0;
        top: 5px;
        @media (max-width: 576px) {
          top: 0px;
        }
        &::after{
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.34' height='8.67' viewBox='0 0 15.34 8.67'%3E%3Cpath id='chevron-left' d='M6.256,12.511,0,6.256,6.256,0' transform='translate(1.414 7.67) rotate(-90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
          content: '';
          position: absolute;
          width: 15px;
          height: 9px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .collapse_wrapper{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 398px;
      .icons_wrapper{
        width: auto;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0px -7px;
        @media (max-width: 576px) {
          margin: 0px -4px;
        }
        .icons_item{
          width: 33.333%;
          padding: 5px 7px;
          display: flex;
          flex-direction: column;
          position: relative;
          @media (max-width: 576px) {
            padding: 5px 4px;
          }
          .icons_inner{

            
            width: 100%;
            position: relative;
            border: 1px solid #707070;
            border-radius: 10px;
            overflow: hidden;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: .3s background-color ease-in-out;


            @media (max-width: 1599px) {
              padding: 10px 5px;
            }

            img{
              height: 30px;
              margin-bottom: 8px;
              display: block;
            }

            .icons_text{

              font-size: 10px;
              color: #333333;
              font-weight: 700;
              text-decoration: none;
              width: 100%;
              text-align: center;
              margin: 0;
              margin-bottom: 10px;
              transition: .3s color ease-in-out;
              text-transform: uppercase;

              @media (max-width: 1599px) {
                font-size: 9px;
              }
              @media (max-width: 576px) {
                font-size: 8px;
              }


            }

            .btn_preview{
              width: 100%;
              display: flex;
              flex-direction: column;
              position: relative;
              background-color: transparent;
              color: #707070;
              border: 1px solid #707070;
              border-radius: 6px;
              text-decoration: none;
              font-weight: 400;
              height: 20px;
              font-size: 10px;
              line-height: 1;
              justify-content: center;
              text-align: center;
              transition: .3s color ease-in-out, .3s background-color ease-in-out, .3s opacity ease-in-out;
              opacity: 0;
              pointer-events: none;
              align-items: center;
              white-space: nowrap;
            }
            
          }

          &:hover{
            .icons_inner{
              background-color: #0079FF;
              color: #fff;
            }
            .btn_preview{
              border: 1px solid #fff;
              color: #fff;
              opacity: 1;
              pointer-events: all;
            }
            .icons_text{
              color: #fff;
            }
            img {
              filter: invert(1);
            }
          }
        }
      }
    }
  }
}


.action_wrapper_config{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  margin-bottom: 20px;
  .cancel_btn{
    font-size: 16px;
    line-height: 1;
    color: #272727;
    width: auto;
    position: relative;
    margin-right: 39px;
    border: none;
    background-color: transparent;
  }
  .submit_btn{

    font-size: 16px;
    line-height: 1;
    color: #ffffff;
    width: auto;
    position: relative;
    padding: 12px 35px;
    border-radius: 4px; 
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #0079FF;
    border: 1px solid #0079FF;
    transition: .3s all ease-in;
    @media (max-width: 576px) {
      padding: 11px 25px;
    }
    &:hover{
      background-color: transparent;
      color: #0079FF;
    }

  }
}