
/* Variables */
$white: #FFFFFF;
$black: #000000;
$font: 'Ubuntu', sans-serif !important;
$font-family-alt: 'Roboto', sans-serif !important;
/* Variables */

/* Footer starts */
.sitefooter {
    background-color: #3E3E3E;
    background-image: url('../../assets/images/about-us/footerbg.png');
    padding: 110px 0 85px;
    @media (max-width: 1199px) {
        padding: 80px 0 65px;
    }
    @media (max-width: 991px) {
        padding: 60px 0 55px;
    }
    li,a,header,p {
        color: $white;
    }
    a {
        text-decoration: none;
        color: $white !important;
        transition: all ease-in-out 0.3s;
        &:hover {
            opacity: .7;
        }
    }
    .custom_container {
        max-width: 1110px;
        display: block;
        margin: auto;
        .ftrcol {
            flex-basis: 25%;
            max-width: 25%;
            padding-bottom: 70px;
            @media (max-width: 991px) {
                flex-basis: 23.33%;
                max-width: 23.33%;
                padding-bottom: 50px;
            }
            @media (max-width: 767px) {
                flex-basis: 100%;
                max-width: 100%;
            }
            .wrap {
                header {
                    font-size: 20px;
                    font-weight: 700;
                    padding-bottom: 25px;
                    @media (max-width: 991px) {
                        padding-bottom: 15px;
                    }
                }
                ul {
                    padding: 0;
                    margin: 0;
                    li {
                        line-height: 40px;
                        font-family: $font;
                        @media (max-width: 991px) {
                            line-height: 36px;
                        }
                        @media (max-width: 767px) {
                            line-height: 34px;
                        }
                        a {
                            text-decoration: none;
                            color: $white;
                            transition: all ease-in-out 0.3s;
                            font-size: 20px;
                            @media (max-width: 991px) {
                                font-size: 18px;
                            }
                            @media (max-width: 767px) {
                                font-size: 17px;
                            }
                            &:hover {
                                opacity: .7;
                            }
                        }
                    }
                }
            }
            &:first-child {
                flex-basis: 40%;
                max-width: 40%;
                @media (max-width: 991px) {
                    flex-basis: 33.33%;
                    max-width: 33.33%;
                }
                @media (max-width: 767px) {
                    flex-basis: 100%;
                    max-width: 100%;
                }
                .wrap {
                    max-width: 350px;
                    header {
                        font-size: 30px;
                        line-height: 1.2;
                        @media (max-width: 991px) {
                            font-size: 28px;
                        }
                        @media (max-width: 767px) {
                            font-size: 25px;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        font-size: 18px;
                        line-height: 40px;
                        font-family: $font-family-alt;
                        @media (max-width: 991px) {
                            font-size: 17px;
                            line-height: 36px;
                        }
                        @media (max-width: 767px) {
                            font-size: 16px;
                            line-height: 34px;
                        }
                    }
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        padding-top: 70px;
                        @media (max-width: 991px) {
                            padding-top: 30px;
                        }
                        li {
                            &+li {
                                margin-left: 60px;
                            }
                        }
                    }
                }
            }
            &:last-child {
                flex-basis: 35%;
                max-width: 35%;
                @media (max-width: 991px) {
                    flex-basis: 43.33%;
                    max-width: 43.33%;
                }
                @media (max-width: 767px) {
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }
        }
        .btmbar {
            padding-top: 30px;
            border-top: 1px solid #FFFFFF;
            display: flex;
            flex-wrap: wrap;
            .col {
                flex: 1 0;
                width: 40%;
                flex-basis: 40%;
                @media (max-width: 767px) {
                    width: 100%;
                    flex-basis: 100%;
                }
                p {
                    margin-bottom: 0;
                    font-size: 18px;
                    opacity: .8;
                    @media (max-width: 767px) {
                        text-align: center;
                    }
                }
                ul {
                    padding: 0;
                    margin: auto;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    @media (min-width: 768px) {
                        justify-content: flex-end;
                    }
                    li {
                        display: inline-block;
                        &+li {
                            margin-left: 33px;
                        }
                        
                        font-size: 18px;
                        opacity: .8;
                    }
                }
                &+.col {
                    width: 60%;
                    flex-basis: 60%;
                    @media (max-width: 767px) {
                        padding-top: 20px;
                        width: 100%;
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
}
/* Footer Ends */