.billboard_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  &::after {
    content: "";
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    opacity: 0.8;
  }
}

.main_head {
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  font-size: 30px;
  line-height: 1.5;
  color: #272727;
  font-weight: 600;
  margin: 0;
  text-align: center;
  width: max-content;
  z-index: 1;
  @media (max-width: 991px) {
    font-size: 24px;
    top: 50px;
  }
  @media (max-width: 576px) {
    font-size: 18px;
    top: 60px;
  }
}

.dashboard_action_wrapper {
  position: absolute;
  top: 45px;
  right: 65px;
  width: max-content;
  display: flex;
  z-index: 9991;
  @media (max-width: 767px) {
    right: 15px;
  }
  @media (max-width: 576px) {
    right: 15px;
    top: 20px;
  }
  .filter_wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 35px;
      height: 35px;
      margin-right: 15px;
    }
    @media (max-width: 576px) {
      margin-right: 5px;
      img {
        width: auto;
        height: 23px;
      }
    }
  }
  .project_wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 35px;
      height: 35px;
      margin-right: 15px;
    }
    @media (max-width: 576px) {
      margin-right: 5px;
      img {
        width: auto;
        height: 23px;
      }
    }
  }
  .action_wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 35px;
      height: 35px;
    }
    @media (max-width: 576px) {
      img {
        width: auto;
        height: 23px;
      }
    }
  }

  .action_items {
    position: absolute;
    top: 100%;
    background-color: #fafcff;
    padding: 30px 22px;
    border-radius: 10px;
    border: 1px solid #b9b9b9;
    width: 181px;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s all ease-in-out;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
    h3 {
      font-size: 18px;
      line-height: 1;
      color: #525252;
      margin: 0;
      margin-bottom: 15px;
    }
    ul {
      width: 100%;
      padding: 0;
      margin: 0;
      li {
        width: 100%;
        position: relative;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        button {
          font-size: 14px;
          line-height: 1;
          padding: 0;
          margin: 0;
          border: none;
          font-weight: 400;
          text-align: left;
          background-color: transparent;
          color: #2f3838;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .header_wrapper.action_active {
    .logo_wrapper {
      transform: translateX(-20px);
    }
  }
}

.project_extra_wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  pointer-events: none;
  overflow: hidden;
  background-color: #ffff;
  @media (max-width: 1023px) {
    align-items: flex-start;
  }
  @media (max-width: 767px) {
    height: calc(100% - 100px);
  }
  @media (max-width: 576px) {
    border-radius: 15px;
    overflow: hidden;
  }
  .project_extra_inner {
    pointer-events: all;
    width: 100%;
    max-height: 85%;
    height: auto;
    background-color: #fff;
    border-radius: 24px;
    max-width: 1163px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 15px;
    box-shadow: 0 5px 21px -3px rgba(0, 0, 0, 0.07);
    @media (max-width: 1599px) {
      max-width: 990px;
    }
    @media (max-width: 1199px) {
      max-width: 680px;
    }
    @media (max-width: 1023px) {
      box-shadow: none;
      max-height: 100%;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      padding: 15px;
      padding-right: 15px;
      padding-top: 50px;
    }

    .project_extra_main {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      padding-right: 20px;
      @media (max-width: 767px) {
        padding-right: 0;
      }
    }
  }
  .image_wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 27px;
    overflow: hidden;
    height: max-content;
    @media (max-width: 1199px) {
      border-radius: 15px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
    .image_container {
      display: flex;
      justify-content: center;
      padding-bottom: calc((502 / 536) * 25%);
      padding-top: calc((502 / 536) * 25%);
      width: 100%;
      img {
        width: 100%;
        outline: 3px solid #000;
        outline-offset: 3px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .content_wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 28px;
    @media (max-width: 1199px) {
      padding-left: 15px;
    }
    @media (max-width: 576px) {
      width: 100%;
      padding-left: 0;
      padding: 15px 0px;
    }
    .heading_icon_wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      align-items: center;
      margin-bottom: 35px;
      @media (max-width: 576px) {
        margin-bottom: 15px;
      }
      .heading_wrapper_project {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1;
        h3 {
          margin: 0px;
          padding: 0px;
          font-size: 30px;
          line-height: 1.5;
          color: #000;
          font-weight: 600;
          width: 100%;
          margin-bottom: 0px;
          text-align: left;
          @media (max-width: 1599px) {
            font-size: 24px;
          }
          @media (max-width: 991px) {
            font-size: 22px;
            margin-bottom: 2px;
          }
        }
        p {
          font-size: 18px;
          line-height: 1.5;
          margin: 0px;
          font-weight: 400;
          width: 100%;
          text-align: left;
          @media (max-width: 1599px) {
            font-size: 16px;
          }
          @media (max-width: 991px) {
            font-size: 14px;
          }
        }
      }
      .icon_wrapper {
        width: 45px;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
          border: none;
          background-color: transparent;
          display: flex;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s all ease;
          &:hover {
            opacity: 0.6;
          }
          @media (max-width: 576px) {
            img {
              height: 30px;
              width: auto;
            }
          }
        }
      }
    }
  }
}

.reaction_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 0;
    margin: 0;
    li {
      width: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-right: 8px;
      &:last-child {
        margin-top: 0;
      }
      button {
        border: none;
        background-color: transparent;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.action_project_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 0;
    margin: 0;
    @media (max-width: 576.98px) {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;
    }
    li {
      width: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-right: 50px;
      @media (max-width: 1199px) {
        margin-right: 25px;
      }
      &:last-child {
        margin-right: 0px;
      }
      button {
        border: none;
        background-color: transparent;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s opacity ease-in-out;
        &:hover {
          opacity: 0.6;
          background-color: transparent;
        }
        img {
          height: 23px;
          width: auto;
        }
        @media (max-width: 1199px) {
          img {
            height: 17px;
            width: auto;
          }
        }
      }
    }
  }
}

.project_result_wrapper {
  width: 100%;
  position: relative;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  white-space: nowrap;
  padding-top: 20px;
  margin-top: auto;
  border-top: 1px solid rgba(112, 112, 112, 0.3);

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #dddddd;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .project_result_item {
    width: 50px;
    display: inline-block;
    margin-right: 12px;
    overflow: hidden;
    margin-bottom: 0;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 576px) {
      width: 36px;
    }
    .project_result_image {
      width: 100%;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      max-width: 138px;
      margin-bottom: 0px;
      @media (max-width: 576px) {
        max-width: 96px;
      }
      &::after {
        content: "";
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        background: -webkit-linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
      }
      .project_result_image_container {
        width: 100%;
        position: relative;
        padding-bottom: calc((96 / 96) * 100%);
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  .project_result_content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    h4 {
      font-size: 13px;
      margin: 0;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-weight: 700;
    }
    p {
      width: 100%;
      line-height: 1.5;
      font-size: 10px;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    a {
      position: absolute;
      opacity: 0;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    span::before {
      color: #4043b4;
    }
  }
}

.back_btn {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 70px;
  top: 17%;
  pointer-events: all;

  z-index: 99;
  @media (max-width: 991px) {
    left: 10px;
    top: 40px;
  }
  @media (max-width: 767px) {
    top: 10px;
    left: 10px;
  }
  button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 25px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.59' height='32.18' viewBox='0 0 17.59 32.18'%3E%3Cpath id='chevron-left' d='M22.969,33.938,9,19.969,22.969,6' transform='translate(-7.5 -3.879)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E%0A");
    font-size: 0;
    line-height: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    @media (max-width: 576px) {
      width: 11px;
      height: 18px;
    }
    /* &::after{
            content: '';
            position: absolute;
            display: block;
            width: 15px;
            height: 15px;
            border-left: 2px solid #000000;
            border-top: 2px solid #000000;
            transform: rotate(-45deg);
        } */
  }
}

.share_wrapper {
  width: 100%;
  position: relative;

  .share_wrapper_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .social_media_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 30px;
      margin-bottom: 40px;
      @media (max-width: 576px) {
        margin-bottom: 20px;
      }

      ul {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        max-width: 470px;
        justify-content: space-between;
        li {
          width: auto;
          display: flex;
          flex-direction: column;
          position: relative;
          a {
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ececee;
            border-radius: 50%;
            transition: 0.3s background-color ease-in-out;
            &:hover {
              background-color: #ececee;
            }
            @media (max-width: 1599px) {
              width: 50px;
              height: 50px;
            }
            @media (max-width: 1199px) {
              width: 40px;
              height: 40px;
              img {
                height: 20px;
                width: auto;
              }
            }
          }
        }
      }
    }

    .copy_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 470px;
      margin-bottom: 40px;
      h3 {
        font-size: 14px;
        line-height: 1;
        font-weight: 600;
        color: #272e43;
        margin: 0;
        margin-bottom: 10px;
      }
      .copy_input {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        input {
          width: 100%;
          border: 1px solid #ececf0;
          height: 45px;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-color: transparent;
          padding: 12px;
          padding-right: 80px;
        }
        .copy {
          width: max-content;
          background-color: transparent;
          color: rgb(109, 108, 108);
          position: absolute;
          top: 0;
          bottom: 0;
          line-height: 1;
          margin: auto;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          padding: 0px 10px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .action_wrapper {
      width: 100%;
      display: flex;
      position: relative;

      .cancel_btn {
        font-size: 16px;
        line-height: 1;
        color: #ffffff;
        width: auto;
        position: relative;
        padding: 11px 25px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #4b9afa;
        border: none;
        margin-right: 10px;
      }
      .share_btn {
        font-size: 16px;
        line-height: 1;
        color: #ffffff;
        width: auto;
        position: relative;
        padding: 11px 25px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #4b9afa;
        border: none;
      }
    }
  }
}

@media (max-width: 576px) {
  .overlay_inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.475);
  }

  .content_wrapper.inner_page {
    position: fixed !important;
    bottom: 0;
    padding: 15px;
    background-color: #fff;
    border-radius: 15px;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .inner_page_2 .heading_icon_wrapper {
    display: none !important;
  }
}

.chat_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: #f4f9ff;
  border-radius: 8px;
  max-height: 100%;
  overflow: hide;
  overflow-y: auto;
  @media (max-width: 576px) {
    padding: 15px;
  }
}

.chat_input_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  .chat_input {
    width: 100%;
    position: relative;
    input {
      width: 100%;
      height: 35px;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #d3d3d3;
      padding: 12px;
    }
  }
  .attachment_btn {
    width: max-content;
    background-color: transparent;
    color: rgb(109, 108, 108);
    position: absolute;
    top: 0;
    bottom: 0;
    line-height: 1;
    margin: auto;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s all ease-in-out;
    &:hover {
      opacity: 0.5;
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.chat_action {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  margin-bottom: 45px;
  margin-left: auto;
  .btn_cancel {
    font-size: 16px;
    line-height: 1;
    color: #a8a8a8;
    width: auto;
    position: relative;
    margin-right: 22px;
    border: none;
    background-color: transparent;
    margin-left: auto;
  }
  .btn_submit {
    font-size: 16px;
    line-height: 1;
    color: #676767;
    width: auto;
    position: relative;
    padding: 11px 30px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #d3d3d3;
    border: none;
    @media (max-width: 576px) {
      padding: 11px 25px;
    }
  }
}

.chat_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .user {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .user_image {
        width: 40px;
        height: 40px;
        background-color: #ffe7cb;
        border-radius: 50%;
        border: 2px solid #fff;
        position: absolute;
        left: 10px;
        top: -16px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      }

      .user_chat {
        width: 100%;
        background-color: #f2f2f2;
        padding: 20px;
        padding-left: 65px;
        border-radius: 50px;
        @media (max-width: 576px) {
          padding: 10px;
          padding-left: 65px;
        }
      }

      .user_text {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .time {
          width: 100%;
          display: block;
          font-size: 12px;
          line-height: 1;
          color: #000;
          margin-bottom: 5px;
          @media (max-width: 576px) {
            font-size: 10px;
          }
        }
        .text {
          width: 100%;
          display: block;
          font-size: 15px;
          line-height: 1;
          color: #000;
          @media (max-width: 576px) {
            font-size: 13px;
          }
        }
      }
    }

    .bot {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .bot_image {
        width: 40px;
        height: 40px;
        background-color: #cd333d;
        border-radius: 50%;
        border: 2px solid #fff;
        position: absolute;
        right: 10px;
        top: -16px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      }

      .bot_chat {
        width: 100%;
        background-color: #f2f2f2;
        padding: 20px;
        padding-left: 65px;
        border-radius: 50px;
        @media (max-width: 576px) {
          padding: 10px;
          padding-left: 60px;
        }
      }

      .bot_text {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .time {
          width: 100%;
          display: block;
          font-size: 12px;
          line-height: 1;
          color: #000;
          margin-bottom: 5px;
          @media (max-width: 576px) {
            font-size: 10px;
          }
        }
        .text {
          width: 100%;
          display: block;
          font-size: 15px;
          line-height: 1;
          color: #000;
          @media (max-width: 576px) {
            font-size: 13px;
          }
        }
      }
    }
  }
}
