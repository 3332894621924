

.video_popup_wrapper{
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    pointer-events: none;
    overflow: hidden;
    .video_popup_inner{
        pointer-events: all;
        width: 100%;
        max-height: 85%;
        height: auto;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        position: relative;
        background: black;
        
        .video_popup_main{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            align-items: center;
        }
    }
    .video_popup_close{
        width: 47px;
        height: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 23px;
        line-height: 1;
        border-radius: 50%;
        position: absolute;
        top: -30px;
        right: -35px;
        background-color: #fff;
        cursor: pointer;
        @media (max-width: 767px) {
            width: 25px;
            height: 25px;  
            font-size: 18px;
            top: -10px;
            right: -13px;
        }
        @media (max-width: 576px) { 
            top: -40px;
            right: 5px;
        }
    }
    .video_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        border-radius: 25px;
        overflow-y: hidden;
       
        .video_container{
            width: 100%;
            position: relative;
            padding-bottom: calc((9 / 16) * 100%);
            video,
            iframe,
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                display: block;
                border: none;
            }
        }
    }
    
}

    :global(.modalback) {
        width: 47px;
        height: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 23px;
        line-height: 1;
        border-radius: 50%;
        position: absolute;
        top: -30px;
        left: -35px;
        background-color: #fff;
        cursor: pointer;
        padding: 0px;
        z-index: 1;
        border: 1px solid #ddd8d8;
        color: transparent;
        padding: 0px;
        margin: 0px;
        @media (max-width: 767px) {
            width: 25px;
            height: 25px;  
            font-size: 18px;
            top: -10px;
            left: -13px;
        }
        &:before{
            content: '';
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.207' height='13.414' viewBox='0 0 7.207 13.414'%3E%3Cpath id='chevron-left' d='M15 18 9 12l6-6' transform='translate(-8.5 -5.293)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            padding: 0px;
            margin: 0px;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: 576px) {
                width: 10px;
            }
        }
    }