.login_wrapper{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;


    .login_inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 30px;
        max-width: 427px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        height: 100%;
        margin: auto;
        height: max-content;
        @media (max-width: 767px) {
            max-width: 340px;
        }
        .back_to_page{
            width: 18px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 54px;
            left: 0;
            z-index: 9;
            cursor: pointer;
            transition: .3s all ease-in-out;
            &:hover{
                opacity: .5;
            }
            @media (max-width: 991px) {
                top: 54px;
                left: 0;
            }
            @media (max-width: 767px) {
                top: 44px;
                left: 10px;
            }
            button{
                width: 18px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.59' height='32.18' viewBox='0 0 17.59 32.18'%3E%3Cpath id='chevron-left' d='M22.969,33.938,9,19.969,22.969,6' transform='translate(-7.5 -3.879)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E%0A");
                font-size: 0;
                line-height: 0;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-color: transparent;
                border: none;
                @media (max-width: 576px) {
                    width: 11px;
                    height: 18px;
                }
            }
        }
    }



    .form_wrapper{
        width: 100%;
        position: relative;
        .form_wrapper_inner{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;

            @media (max-width: 576.98px) {
                max-width: 260px;
                margin: 0px auto;
            }



            .from_group{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: 50px;
                @media (max-width: 1599px) {
                    margin-bottom: 30px;
                }
                label{
                    font-size: 22px;
                    line-height: 1;
                    color: #000;
                    text-decoration: none;
                    font-weight: 500;
                    width: 100%;
                    margin-bottom: 18px;
                    @media (max-width: 1599px) {
                        font-size: 20px;
                    }
                    @media (max-width: 576.98px) {
                        font-size: 16px;
                    }
                }
                input{
                    width: 100%;
                    border: none;
                    background-color: rgba(200, 206, 218, 0.20);
                    height: 56px;
                    border-radius: 50px;
                    padding: 20px;
                    font-size: 20px;
                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                    @media (max-width: 576.98px) {
                        height: 40px;
                        font-size: 16px;
                        line-height: 1;
                        padding: 8px 15px;
                    }
                }
            }
          
            .userlogin_checkbox_align{
                margin-top: 20px;
                display: flex;
                .userlogin_label{
                    font-weight: normal;
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #162D43;
                    padding-left: 5px;
                    a {
                      color: #208CE1;
                    }
                  }
              }

input[type="checkbox"] {
    display: none;
}
input[type="checkbox"] + label {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    a {
        color: #4043B4;
        text-decoration: underline;
        transition: .4s;
        &:hover {
            color: #000;
        }
    }
    .check {
        display: inline-block;
        border: 1px solid #000;
        width: 21px;
        height: 21px;
        position: relative;
        margin-right: 10px;
    }
}
input[type="checkbox"]:checked + label {
    .check {
        &:after {
            content: '✔';
            display: inline-block;
            font-size: .8em;
            width: 100%;
            height: 100%;
            line-height: 19px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

            .login_btn{
                font-size: 20px;
                line-height: 1;
                color: #ffffff;
                width: 100%;
                position: relative;
                padding: 11px 30px;
                border-radius: 6px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: #0079FF;
                border: 1px solid #0079FF;
                max-width: 248px;
                height: 57px;
                transition: .3s all ease-in-out;
                margin: auto;
                &:hover{
                    background-color: transparent;
                    color: #0079FF;
                }
                @media (max-width: 576.98px) {
                    max-width: 140px;
                    height: 35px;
                    font-size: 14px;
                }
            }
        }
    }

    .action_wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        p{
            font-size: 22px;
            line-height: 1.5;
            color: #000;
            margin: 0px;
            @media (max-width: 576px) {
                font-size: 14px;
            }


            a{
                color: #4043B4;
                text-decoration: underline;
            }
        }
    }
}


.social_media_login{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    margin-bottom: 50px;
    .facebook{
        width: calc(50% - 10px);
        background-color: #4267B2;
        font-size: 20px;
        line-height: 1;
        height: 56px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: none;
        -webkit-appearance: none;
        appearance: none;
        transition: .3s all ease-in-out;
        @media (max-width: 576px) {
            font-size: 14px;
            line-height: 1;
            height: 35px;
            width: calc(50% - 7px);
        }
        &:hover{
            background-color: #314F8C;
        }
        img{
            margin-right: 14px;
            @media (max-width: 576px) {
                width: 8px;
                margin-right: 6px;
            }
        }
    }
    .google{
        width: calc(50% - 10px);
        background-color: #DD4B39;
        font-size: 20px;
        line-height: 1;
        height: 56px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: none;
        -webkit-appearance: none;
        appearance: none;
        transition: .3s all ease-in-out;
        @media (max-width: 576px) {
            font-size: 14px;
            line-height: 1;
            height: 35px;
            width: calc(50% - 7px);
        }
        &:hover{
            background-color: #C93724;
        }
        img{
            margin-right: 14px;
            @media (max-width: 576px) {
                width: 15px;
                margin-right: 6px;
            }
        }
    }
}

.logo_wrapper_inner{
    width: 100%;
    max-width: 284px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 80px;
    @media (max-width: 1199.98px) {
        margin-bottom: 60px;
    }
    @media (max-width: 576.98px) {
        max-width: 200px;
    }
    .container{
        width: 100%;
        position: relative;
        padding-bottom: calc((68 / 284) * 100%);
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            cursor: pointer;
        }
    }
}

.rq {
    color:#ff0000;
    font-weight: 600;
}

.display__message{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
    p{
        font-size: 22px;
        line-height: 1.5;
        color: grey;
        margin: 0px;
        @media (max-width: 576px) {
            font-size: 14px;
        }


        a{
            color: #4043B4;
            text-decoration: underline;
        }
    }
}