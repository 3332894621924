.video_image_wrapper{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    .video_image_inner{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #0079FF;
        height: auto;
        padding: 22px 24px;
        margin: auto;
        border-radius: 14px;
        overflow: hidden;

        @media (max-width: 1599px) {
            padding: 18px;
        }

        @media (max-width: 576px) {
            padding:15px;
        }


        .close_icon_wrapper{
            position: absolute;
            right: 15px;
            top: 15px;
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            z-index: 99;
            cursor: pointer;
            transition: .3s all ease-in-out;
            &:hover{
                opacity: .6;
            }
            button{
                color: inherit;
                background-color: transparent;
                font-weight: inherit;
                border: none;
                font-size: inherit;
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
            }
        }


        .heading_wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            padding-right: 80px;
            margin-bottom: 30px;
            @media (max-width: 1599px) {
                margin-bottom: 25px;
            }
            @media (max-width: 991px) {
                padding-right: 40px;
                margin-bottom: 20px;
            }
            @media (max-width: 576px) {
                padding-right: 30px;
            }
            h2{
                font-size: 25px;
                line-height: 1.5;
                color: #fff;
                font-weight: 600;
                text-decoration: none;
                width: 100%;
                margin: 0;
                margin-bottom: 10px;
                @media (max-width: 1599px) {
                    font-size: 20px;
                }
                
                @media (max-width: 991px) {
                    font-size: 18px;
                }
                @media (max-width: 576px) {
                    font-size: 16px;
                }
            }
            p{
                font-size: 18px;
                line-height: 1.3;
                color: #fff;
                font-weight: 400;
                text-decoration: none;
                width: 100%;
                margin: 0;
                @media (max-width: 1599px) {
                    font-size: 16px;
                }
                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }
        }

    }


    .tab_heading{
        width: 100%;
        position: relative;
        
        .tab_heading_inner{
            width: auto;
            padding-left: 23px;
            display: flex;
            position: relative;
            border-bottom: 1px solid #fff;
            margin: 0px -24px;
            margin-bottom: 36px;
            @media (max-width: 1599px) {
                margin-bottom: 25px;
            }
        }
        
        li,
        .tab_heading_item{
            width: auto;
            display: flex;
            flex-direction: column;
            position: relative;
            
            button,
            .tab_heading_text{
                width: auto;
                border: none;
                background-color: transparent;
                color: #fff;
                font-weight: 400;
                text-decoration: none;
                padding: 10px 40px;
                font-size: 20px;
                line-height: 1;
                margin-bottom: -1px;
                @media (max-width: 1599px) {
                    font-size: 16px;
                    padding: 10px 35px;
                }
                
                @media (max-width: 576px) {
                    padding: 10px 15px;
                    font-size: 14px;
                }
                &:global(.active){
                    background-color: transparent;
                    border: 1px solid #fff;
                    border-bottom: 0;
                    background-color: #0079FF;
                    color: #fff;
                    font-weight: 700;
                    .tab_heading_text{
                        font-weight: 600 !important;
                    }
                }
            }

        }
    }




    .tab_main{
        width: 100%;
        position: relative;
        .tab_main_inner{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;


            
        }
    }
}



.tab_item{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: 20px;
                padding: 0px 40px;
                @media (max-width: 1599px) {
                    padding: 0px 35px;
                }
                @media (max-width: 1199px) {
                    margin-bottom: 0;
                }
                @media (max-width: 576px) {
                    padding: 0px 14px;
                }
                 
                .tab_item_inner{
                    width: 100%;
                    position: relative;

                    
                }

                .form_wrapper{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;

                    input{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        background-color: #fff;
                        height: 45px;
                        border: none;
                        border-radius: 4px;
                        flex: 1;
                    }


                    button{
                        border-radius: 4px;
                        width: 142px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 45px;
                        border: none;
                        font-size: 16px;
                        font-weight: 400;
                        background-color: #0055B4;
                        text-decoration: none;
                        transition: .3s all ease-in-out;
                        margin-left: 30px;
                        color: #fff;
                        @media (max-width: 991px) {
                            margin-left: 10px;
                        }
                        @media (max-width: 576px) {
                            width: 100px;
                        }
                        &:hover{
                            background-color: #014491;
                        }
                    }
                }



                .input_wrapper{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    position: relative;


                    .input_file_main{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 20px;
                        @media (max-width: 1599px) {
                            margin-bottom: 20px;
                        }

                        .input_file{
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            flex: 1;
                            position: relative;
                            align-items: center;
                            input{
                                position: absolute;
                                left: 0;
                                top: 0;
                                opacity: 0;
                                z-index: 1;
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                            }
                            span{
                                font-size: 14px;
                                line-height: 1.2;
                                color: #fff;
                                font-weight: 400;
                                margin-left: 30px;
                                flex: 1;
                                @media (max-width: 576px) {
                                    margin-left: 10px;
                                    font-size: 12px;
                                }
                            }
                        }


                        .input_box{
                            width: 119px;
                            height: 37px;
                            background-color: #fff;
                            border-radius: 4px;
                            font-size: 14px;
                            line-height: 1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #0079FF;
                        }


                        .add_more{
                            width: 37px;
                            height: 37px;
                            background-color: #fff;
                            border-radius: 4px;
                            overflow: hidden;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.377' height='8.6' viewBox='0 0 8.377 8.6'%3E%3Cg id='Group_27' data-name='Group 27' transform='translate(1 1)'%3E%3Cline id='Line_1' data-name='Line 1' y2='6.6' transform='translate(3.188 0)' fill='none' stroke='%230079ff' stroke-linecap='round' stroke-width='2'/%3E%3Cline id='Line_2' data-name='Line 2' y2='6.377' transform='translate(6.377 3.3) rotate(90)' fill='none' stroke='%230079ff' stroke-linecap='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
                            background-position: center;
                            background-size: 11px;
                            background-repeat: no-repeat;
                            border: none;
                            font-size: 0;
                            line-height: 1;
                        }
                    }
                    .submit_btn{
                        border-radius: 4px;
                        width: 142px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 45px;
                        border: none;
                        font-size: 16px;
                        font-weight: 400;
                        background-color: #0055B4;
                        text-decoration: none;
                        transition: .3s all ease-in-out;
                        color: #fff;
                        margin-top: 15px;
                        @media (max-width: 576px) {
                            width: 100px;
                        }
                        &:hover{
                            background-color: #014491;
                        }
                    }
                }





            }


.video_thmb{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
        background-color: #0079FF;
        width: 16px;
    }
    
    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color: #0079FF;
    }
    
    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color: #005bc4;
        border-radius: 16px;
        border: 4px solid #0079FF;
    }
    
    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display:none;
    }
    .video_thmb_inner{
        width: max-content;
        position: relative;
        display: flex;
        margin-bottom: 6px;
    }
    .image_wrapper{
        width: 50px;
        display: inline-block;
        position: relative;
        margin-right: 10px;
        border-radius: 8px;
        overflow: hidden;
        .container{
            width: 100%;
            position: relative;
            padding-bottom: calc((50 / 50) * 100%);
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}