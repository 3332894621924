.search_billboard_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20%;
    display: flex;
    @media (max-width: 1199px) {
        top: 24%;
    }
    @media (max-width: 767px) {
        top: 45%;
    }
    @media (max-width: 576px) {
        top: 27%;
    }
    .search_billboard_inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        .search_result_wrapper {
            max-width: 1146px;
            display: block;
            margin: auto;
            @media (max-width: 1599.98px) {
                max-width: 990px;
            }
            @media (max-width: 1199.98px) {
                max-width: 767px;
            }
            @media (max-width: 1023.98px) {
                max-width: 500px;
            }
            @media (max-width: 767px) {
                max-width: calc(100% - 30px);
            }
            .landing_search_result {
                left: 0;
            }
        }
    }

    .map_filter_header {
        width: 100%;
        max-width: 1146px;
        display: flex;
        position: relative;
        background-color: #0079ff;
        border-radius: 14px;
        overflow-y: hidden;
        align-items: center;
        padding: 25px;
        z-index: 1;
        @media (max-width: 1599.98px) {
            padding: 15px 25px;
            max-width: 990px;
        }
        @media (max-width: 1199.98px) {
            max-width: 767px;
        }
        @media (max-width: 1023.98px) {
            padding: 13px 15px;
        }
        @media (max-width: 1023.98px) {
            max-width: 500px;
        }
        @media (max-width: 767px) {
            max-width: calc(100% - 30px);
        }

        &::after {
            width: 125px;
            height: 7px;
            background-color: #fff;
            border-radius: 2px;
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
            bottom: -3px;
            @media (max-width: 576.98px) {
                width: 52px;
            }
        }
        .map_search_icon {
            width: 25px;
            display: flex;
            flex-direction: column;
            position: relative;
            margin-right: 30px;
            @media (max-width: 576.98px) {
                margin-right: 5px;
            }
            span {
                font-size: 25px;
                @media (max-width: 576.98px) {
                    font-size: 18px;
                }
            }
        }
        .map_input_wrapper {
            width: 100%;
            flex: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            position: relative;
            input {
                border: none;
                background-color: transparent;
                color: #fff;
                text-decoration: none;
                line-height: 1;
                font-weight: 300;
                font-family: "HelveticaNeue";
                font-size: 26px;
                @media (max-width: 1599.98px) {
                    font-size: 20px;
                }
                @media (max-width: 576.98px) {
                    font-size: 17px;
                }
                &:focus {
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
                &::-webkit-input-placeholder {
                    /* Edge */
                    color: #fff;
                    opacity: 1;
                    text-decoration: none;
                    line-height: 1.5;
                    font-weight: 300;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #fff;
                    opacity: 1;
                    text-decoration: none;
                    line-height: 1.5;
                    font-weight: 300;
                }

                &::placeholder {
                    color: #fff;
                    opacity: 1;
                    text-decoration: none;
                    line-height: 1.5;
                    font-weight: 300;
                }
            }
        }
        .map_action_wrapper {
            width: 33px;
            height: 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            @media (max-width: 576.98px) {
                width: 30px;
                height: 30px;
            }
            .map_message_icon {
                position: absolute;
                opacity: 0;
                transition: 0.3s opacity ease-in-out;
                border: none;
                background-color: transparent;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                cursor: pointer;
                pointer-events: none;
                &.active {
                    opacity: 0.5;
                    pointer-events: all;
                }
                &.active:hover {
                    opacity: 1;
                }
                span {
                    font-size: 33px;
                    @media (max-width: 576.98px) {
                        font-size: 20px;
                    }
                }
            }
            .map_camera_icon {
                position: absolute;
                opacity: 0;
                transition: 0.3s opacity ease-in-out;
                border: none;
                background-color: transparent;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                cursor: pointer;
                pointer-events: none;
                &.active {
                    opacity: 0.5;
                    pointer-events: all;
                }
                &.active:hover {
                    opacity: 1;
                }
                span {
                    font-size: 33px;
                    @media (max-width: 576.98px) {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.search_billboard_result {
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    max-width: calc(100% - 130px);
    margin-left: auto;
    margin-right: auto;
    max-height: calc(100vh - 310px);
    overflow: hidden;
    overflow-y: auto;
    padding: 75px 105px;
    @media (max-width: 1599px) {
        padding: 50px 70px;
    }
    @media (max-width: 767px) {
        max-width: calc(100% - 30px);
        padding: 35px 30px;
    }
    @media (max-width: 576px) {
        max-width: 100%;
        max-height: calc(100vh - 190px);
    }
    .search_billboard_result_inner {
        width: auto;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        padding-bottom: 30px;
        margin: 0px -38px;
        @media (max-width: 1599px) {
            margin: 0px -20px;
        }
        @media (max-width: 991px) {
            margin: 0px -10px;
        }
        @media (max-width: 767px) {
            margin: 0px -5px;
        }

        .result_item_wrapper {
            cursor: pointer;
            width: 20%;
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 0px 38px;
            margin-bottom: 22px;
            @media (max-width: 1599px) {
                padding: 0px 20px;
            }
            @media (max-width: 1199px) {
                width: 25%;
            }
            @media (max-width: 991px) {
                width: 33.333%;
                padding: 0px 10px;
            }
            @media (max-width: 767px) {
                padding: 0px 5px;
            }
            @media (max-width: 576px) {
                width: 50%;
            }

            .result_item_inner {
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;

                .collage_wrapper {
                    width: 100%;
                    position: relative;
                    border-radius: 8px;
                    overflow: hidden;
                    margin-bottom: 10px;
                    .imgsec {
                        line-height: 0;
                        display: flex;
                        align-items: center;
                        @media (max-width: 576px) {
                        }
                        .big_image {
                            line-height: 0;
                            max-width: 68%;
                            width: 100%;
                            .image_wrapper {
                                position: relative;
                                padding-bottom: calc((97 / 172) * 100%);
                                .image_container {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        left: 0;
                                        top: 0;
                                        object-fit: cover;
                                        position: absolute;
                                    }
                                }
                            }
                        }
                        .small_image {
                            line-height: 0;
                            max-width: 32%;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            position: relative;

                            .image_wrapper {
                                width: 100%;
                                .image_container {
                                    padding-bottom: calc((48 / 80) * 100%);
                                    position: relative;
                                    img {
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }

                    .image_wrapper {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        .image_container {
                            width: 100%;
                            position: relative;
                            /*  padding-bottom: calc((94 / 252) * 100%);
                @media (max-width: 576px) {
                  padding-bottom: calc((94 / 252) * 100%);
                } */
                            img {
                                max-width: 100%;
                                /*  position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; */
                            }
                        }
                    }

                    /* .image_wrapper:nth-child(1){ } */
                    .image_wrapper:nth-child(2) {
                        .image_container {
                            /* padding-bottom: calc((94 / 252) * 100%);
                @media (max-width: 576px) {
                  padding-bottom: calc((94 / 252) * 100%);
                } */
                        }
                    }
                    .image_wrapper:nth-child(3) {
                        max-width: 34%;
                        .image_container {
                            /* padding-bottom: calc((94 / 252) * 100%);
                @media (max-width: 576px) {
                  padding-bottom: calc((94 / 252) * 100%);
                } */
                        }
                    }
                }
                .tag_red {
                    width: max-content;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: #ce0909;
                    font-size: 11px;
                    line-height: 1;
                    color: #fff;
                    font-weight: 700;
                    height: 24px;
                    padding: 0px 23px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    overflow: hidden;
                    @media (max-width: 576.98px) {
                        top: 77%;
                        right: 0;
                        height: 16px;
                        width: 36px;
                        font-size: 10px;
                    }
                }
                .tag_green {
                    width: max-content;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: #09ce2f;
                    font-size: 11px;
                    line-height: 1;
                    color: #fff;
                    font-weight: 700;
                    height: 24px;
                    padding: 0px 23px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    overflow: hidden;
                    @media (max-width: 576.98px) {
                        top: 77%;
                        right: 0;
                        height: 16px;
                        width: 36px;
                        font-size: 10px;
                    }
                }

                h3 {
                    margin: 0px;
                    margin-bottom: 0px;
                    position: relative;
                    font-size: 18px;
                    line-height: 1.3;
                    color: #272727;
                    font-weight: 600;
                    margin-bottom: 2px;
                    @media (max-width: 1199px) {
                        font-size: 15px;
                    }
                    @media (max-width: 576px) {
                        font-size: 12px;
                        padding-right: 50px;
                    }
                }
                p {
                    margin: 0px;
                    position: relative;
                    /* font-size: 14px; */
                    font-size: 12px;
                    line-height: 1.2;
                    color: #272727;
                    font-weight: 400;
                    @media (max-width: 1199px) {
                        /* font-size: 12px; */
                        font-size: 10px;
                    }
                    @media (max-width: 576px) {
                        padding-right: 50px;
                        /* font-size: 11px; */
                        font-size: 8px;
                    }
                }
            }
        }
    }
}

.read_more_wrapper_extra {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #fff;
    max-width: calc(100% - 130px);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    @media (max-width: 767px) {
        max-width: calc(100% - 30px);
    }
    @media (max-width: 576px) {
        max-height: calc(100% - 110px);
        height: 30px;
        bottom: 0%;
    }
    button {
        width: 40px;
        height: 40px;
        background-color: transparent;
        color: #fff;
        font-size: 0;
        border: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43.703' height='16.168' viewBox='0 0 43.703 16.168'%3E%3Cpath id='chevron-left' d='M18.13,41.627,9,23.814,18.13,6' transform='translate(-1.962 22.168) rotate(-90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='6'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-right: 20px;
        @media (max-width: 576px) {
            width: 20px;
            height: 20px;
        }
    }
}

.no__results {
    text-align: center;
    color: red;
}
