*{
    box-sizing: border-box;
    letter-spacing: 0;
}
body,html{
    padding: 0;
    margin: 0;
    position: relative;
    font-family: "HelveticaNeue";
    font-weight: 400;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
}
select,
option,
textarea,   
input{
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
button{
  cursor: pointer;
}




@font-face {
    font-family: 'icomoon';
    src:  url('../../../assets/other/icomoon.eot?nfub3p');
    src:  url('../../../assets/other/icomoon.eot?nfub3p#iefix') format('embedded-opentype'),
      url('../../../assets/other/icomoon.ttf?nfub3p') format('truetype'),
      url('../../../assets/other/icomoon.woff?nfub3p') format('woff'),
      url('../../../assets/other/icomoon.svg?nfub3p#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-direction:before {
    content: "\e911";
    color: #017afb;
  }
  .icon-message:before {
    content: "\e912";
  }
  .icon-arrow-bottom:before {
    content: "\e900";
  }
  .icon-arrow-right:before {
    content: "\e901";
    color: #0079ff;
  }
  .icon-camera:before {
    content: "\e902";
    color: #fff;
  }
  .icon-map-01:before {
    content: "\e903";
    color: #fff;
  }
  .icon-map-02:before {
    content: "\e904";
    color: #0276f2;
  }
  .icon-map-03:before {
    content: "\e905";
  }
  .icon-map-04:before {
    content: "\e906";
    color: #0276f2;
  }
  .icon-map-05:before {
    content: "\e907";
    color: #ffc311;
  }
  .icon-map-06:before {
    content: "\e908";
    color: #0276f2;
  }
  .icon-map-07:before {
    content: "\e909";
    color: #0276f2;
  }
  .icon-map-08:before {
    content: "\e90a";
    color: #0276f2;
  }
  .icon-map .path1:before {
    content: "\e90b";
    color: rgb(0, 0, 0);
  }
  .icon-map .path2:before {
    content: "\e90c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-map .path3:before {
    content: "\e90d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-map .path4:before {
    content: "\e90e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-search:before {
    content: "\e90f";
    color: #fff;
  }
  .icon-send:before {
    content: "\e910";
    color: #fff;
  }
    
  


  @font-face {
    font-family: 'Untitled Sans';
    src: url('../../../assets/other/UntitledSans-Bold.woff2') format('woff2'),
        url('../../../assets/other/UntitledSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../../../assets/other/HelveticaNeueMedium.woff2') format('woff2'),
        url('../../../assets/other/HelveticaNeueMedium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../../../assets/other/HelveticaNeueBold.woff2') format('woff2'),
        url('../../../assets/other/HelveticaNeueBold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../../../assets/other/HelveticaNeue.woff2') format('woff2'),
        url('../../../assets/other/HelveticaNeue.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

