$font-family: "HelveticaNeue";
/* Variables */
$white: #FFFFFF;
$black: #000000;
$font: 'Ubuntu', sans-serif !important;
$font-family-alt: 'Roboto', sans-serif !important;
/* Variables */

.usecasesec {
    padding-bottom: 40px;
    .casestabwrapper {
        max-width: 1490px;
        display: block;
        margin: auto;
        :global(.nav-tabs) {
            border: none;
            :global(.nav-item) {
                margin-right: 30px;
                font-size: 30px;
                font-weight: 400;
                font-size: 24px;
                @media (min-width: 1200px) {
                    font-size: 27px;
                    margin-right: 50px;
                }
                @media (min-width: 1800px) {
                    font-size: 30px;
                    margin-right: 70px;
                }
                :global(.nav-link) {
                    padding: 0;
                    border: none;
                    color: #08150A;
                    font-family: $font;
                    &:global(.active) {
                        font-weight: 700;
                    }
                }
            }
        }
        :global(.tab-content) {
            padding-top: 0px;
            @media (min-width: 767px) {
                padding-top: 35px;
            }
            @media (min-width: 1200px) {
                padding-top: 45px;
            }
            @media (min-width: 1800px) {
                padding-top: 58px;
            }
            :global(.card-body) {
                padding: 0 !important;
            }
        }
        .itemsList {
            .row {
                --bs-gutter-x: 1.5rem;
                --bs-gutter-y: 0;
                display: flex;
                flex-wrap: wrap;
                margin-left: -12px;
                margin-right: -12px;
                @media (min-width: 1200px) {
                    margin-left: -15px;
                    margin-right: -15px;
                }
                &>*, &:global(.cols) {
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    @media (min-width: 1200px) {
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }
                }
            }
            .itemwrap {
                background-color: $white;
                .imgsec {
                    margin: auto;
                    line-height: 0;
                    max-width: 100%;
                    img {
                        max-width: 100%;
                    }
                }
                .itemcnt {
                    border: 1px solid #EEEEEE;
                    border-width: 0 1px 1px;
                    padding: 20px 20px;
                    min-height: 180px;
                    @media (min-width: 992px) {
                        min-height: 200px;
                    }
                    @media (min-width: 1200px) {
                        min-height: 220px;
                        padding: 30px 20px;
                    }
                    @media (min-width: 1800px) {
                        min-height: 240px;
                    }
                    .titlesec {
                        font-weight: 700;
                        line-height: 1.2;
                        color: #2E2D26;
                        font-size: 21px;
                        padding-bottom: 10px;
                        font-family: $font;
                        @media (min-width: 1200px) {
                            font-size: 22px;
                            padding-bottom: 15px;
                        }
                        @media (min-width: 1800px) {
                            font-size: 24px;
                            padding-bottom: 22px;
                        }
                    }
                    p {
                        font-size: 18px;
                        color: #2E2D26;
                        font-family: $font-family;
                        opacity: .5;
                        line-height: 1.35;
                    }
                }
            }
        }
    }
}


.responsive_tabs {
	padding: 0;
	:global(.nav-tabs) {
		display: none;
        @media (min-width: 768px) {
			display: flex;
        }
	}
    :global(.card) {
        border: none;
        @media (min-width: 768px) {
            border: none;
            :global(.card-header) {
                display: none;
            }
            :global(.collapse) {
                display: block;
            }
        }
        :global(.card-header) {
            padding: 0;
            border-radius: 3px;
            margin-top: 10px;
            background: transparent;
            border: 1px solid #d5d5d5;
            header {
                a {
                    display: block;
                    padding: 10px 15px;
                    color: #2E2D26;
                    font-size: 20px;
                    font-family: $font;
                    text-decoration: none;
                    &.collapsed {
                    }
                }
            }
        }
    }
    :global(.tab-pane) {
        @media (max-width: 767px) {
			display: block !important;
			opacity: 1 !important;
        }
    }
}
