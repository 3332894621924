.payment_info_wrapper{
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}



.payment_info_main{
    width: 100%;
    max-width: 522px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
    @media (max-width: 767px) {
        margin-top: 30px;
        margin-bottom: 30px;
    }


    .back_to_page{
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -1%;
        left: -35%;
        z-index: 9;
        cursor: pointer;
        transition: .3s all ease-in-out;
        &:hover{
            opacity: .5;
        }
        @media (max-width: 991px) {
            top: -1%;
            left: -15%;
        }
        @media (max-width: 767px) {
            top: -1%;
            left: 3%;
        }
        Button{
            width: 18px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.59' height='32.18' viewBox='0 0 17.59 32.18'%3E%3Cpath id='chevron-left' d='M22.969,33.938,9,19.969,22.969,6' transform='translate(-7.5 -3.879)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E%0A");
            font-size: 0;
            line-height: 0;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: transparent;
            border: none;
            @media (max-width: 576px) {
                width: 11px;
                height: 18px;
            }
        }
    }

    .payment_info{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-left: 15px;
        padding-right: 15px;


        .price_wrapper{
            width: 100%;
            position: relative;


            .head{
                font-size: 40px;
                line-height: 1;
                color: #282F39;
                width: 100%;
                text-align: center;
                margin: 0px;
                margin-bottom: 60px;
                font-weight: 700;
                @media (max-width: 1599px) {
                    margin-bottom: 20px;
                }
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                @media (max-width: 576px) {
                    font-size: 24px;
                    margin-bottom: 25px;
                }
            }


            .price_wrapper_main{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                justify-content: center;
                align-items: flex-start;
                span{
                    font-size: 120px;
                    font-weight: 700;
                    text-align: center;
                    width: auto;
                    line-height: 1;
                    @media (max-width: 576px) {
                        font-size: 80px;
                    }
                }
                .dollar{
                    color: #0079FF;
                    font-size: 60px;
                    padding-right: 15px;
                    margin-top: 5px;
                    @media (max-width: 576px) {
                        font-size: 40px;
                    }
                }
            }
        }
    }
}

.payment_main_info{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 20px;
    align-items: center;
    @media (max-width: 767px) {
        padding-right: 0;    
    }

    .image_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        
        // .image_container{
        //     width: 100%;
        //     position: relative;
        //     padding-bottom: calc((502 / 536) * 100%);
        //     img{
        //         position: absolute;
        //         left: 0;
        //         top: 0;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //         object-position: center;
        //     }
        // }


        .slider_wrapper{
            width: 100%;
            position: relative;
            max-width: 322px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 80px;
            padding-top: 35px;  
            @media (max-width: 576px) {
                margin-bottom: 50px;
            }
          
            .slider_wrapper_inner{
                width: 100%;
                padding-bottom: calc((172 / 322) * 100%);
                position: relative;

                .slider_image{
                    width: 100%;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    .slick-list{
                        height: 100%;
                        overflow: visible;
                    }
                    .slick-dots{
                        position: absolute;
                        bottom: -55px;
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        padding: 0;
                        align-items: center;
                        @media (max-width: 576px) {
                            bottom: -26px;
                        }
                        li{
                            font-size: 0px;
                            width: 8px;
                            height: 8px;
                            margin-right: 5px;
                            transition: .3s all ease-in-out;
                            button{
                                padding: 0px;
                                font-size: 0px;
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                overflow: hidden;
                                border: none;
                                background: rgb(26,87,250);
                                background: -moz-linear-gradient(45deg, rgba(26,87,250,1) 0%, rgba(50,172,252,1) 100%);
                                background: -webkit-linear-gradient(45deg, rgba(26,87,250,1) 0%, rgba(50,172,252,1) 100%);
                                background: linear-gradient(45deg, rgba(26,87,250,1) 0%, rgba(50,172,252,1) 100%);
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1a57fa",endColorstr="#32acfc",GradientType=1);
                                transition: .3s all ease-in-out;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            &.slick-active{
                                width: 12px;
                                height: 12px;
                                button{
                                    width: 12px;
                                    height: 12px;
                                    background: rgb(41,27,151);
                                    background: -moz-linear-gradient(45deg, rgba(41,27,151,1) 0%, rgba(83,54,204,1) 100%);
                                    background: -webkit-linear-gradient(45deg, rgba(41,27,151,1) 0%, rgba(83,54,204,1) 100%);
                                    background: linear-gradient(45deg, rgba(41,27,151,1) 0%, rgba(83,54,204,1) 100%);
                                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#291b97",endColorstr="#5336cc",GradientType=1);
                                }
                            }
                        }
                    }
                    .slick-track{
                        transform: none !important;
                        width: auto !important;
                        height: 100%;
                        overflow: visible;
                    }

                    .slider_item{
                        width: 322px;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        transition: .3s all ease-in-out;
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        margin: auto;
                        pointer-events: none;
                        @media (max-width: 767px) {
                            width: 280px;
                        }
                        &.slick-active{
                            transform: scale(1);
                            opacity: 1;
                            pointer-events: all;
                            & + .slider_item{
                                transform: scale(0.86);
                                opacity: 0.6;
                                top: -20px;
                            }
                            & + .slider_item + .slider_item{
                                transform: scale(0.75);
                                opacity: 0.4;
                                top: -36px;
                            }
                            & + .slider_item + .slider_item + .slider_item{
                                transform: scale(.4);
                                opacity: .4;
                            }
                        }
                        
                        .image_wrapper{
                            width: 100%;
                            position: relative;
                            .image_container{
                                width: 100%;
                                position: relative;
                                padding-bottom: calc((172 / 322) * 100%);
                                img{
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}



.card_details_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;


    .card_detail_head{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 35px;
        @media (max-width: 576px) {
            margin-bottom: 40px;
        }
        h3{
            font-size: 24px;
            line-height: 1;
            color: #3C3C3C;
            width: 100%;
            text-align: left;
            margin: 0px;
            margin-bottom: 0px;
            font-weight: 700;
            @media (max-width: 576px) {
                font-size: 20px;
            }
        }
    }
    .form_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .form_wrapper_inner{
            width: auto;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            margin: 0px -15px;
            @media (max-width: 576px) {
                margin: 0px -7px;
            }

            .config_input{
                width: 100%;
                flex: 1;
                position: relative;
                input{
                  width: 100%;
                  position: relative;
                  display: block;
                  border: 1px solid #D5D5D5;
                  border-radius: 5px;
                  height: 45px;
                  padding: 10px ;
                  @media (max-width: 576px) {
                    height: 32px;
                  }
                  &:focus,
                  &:focus-visible{
                    outline: none;
                    box-shadow: none;
                  }
                }
                label{
                  font-size: 18px;
                  line-height: 1;
                  color: #3C3C3C;
                  font-weight: 400;
                  background-color: #fff;
                  position: absolute;
                  z-index: 1;
                  padding: 0px 7px;
                  left: 16px;
                  top: -10px;
                  @media (max-width: 1199px) {
                    font-size: 16px;
                    top: -8px;
                  }
                  @media (max-width: 767px) {
                    font-size: 12px;
                    top: -7px;
                    left: 7px;
                  }
                  span{
                    color: #FF0000;
                  }
                }
            }

            .card_number{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 0px 15px;
                max-width: calc(100% - 120px);
                margin-bottom: 35px;
                @media (max-width: 576px) {
                    /* padding: 0px 7px; */
                    margin-bottom: 20px;
                }
                @media (max-width: 365px) {
                     padding: 0px 7px;
                }
                
            }
            .card_cvc{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 0px 15px;
                max-width: 120px;
                margin-bottom: 35px;
                @media (max-width: 576px) {
                    /* padding: 0px 7px; */
                    margin-bottom: 20px;
                }
                @media (max-width: 365px) {
                     padding: 0px 7px;
                }
            }

            .card_name{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 0px 15px;
                max-width: 100%;
                margin-bottom: 35px;
                @media (max-width: 576px) {
                    /* padding: 0px 7px; */
                    margin-bottom: 20px;
                }
                @media (max-width: 365px) {
                     padding: 0px 7px;
                }
            }

            .card_expire{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 0px 15px;
                max-width: calc(100% - 250px);
                @media (max-width: 576px) {
                    margin-bottom: 20px;
                    /* padding: 0px 7px; */
                    max-width: calc(100% - 200px);
                }
                @media (max-width: 365px) {
                    max-width: calc(100% - 140px);
                    padding: 0px 7px;
                }
                
            }

            .card_submit{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 0px 15px;
                max-width: 250px;
                @media (max-width: 576px) {
                    /* padding: 0px 4px; */
                    max-width: 165px;
                }
                @media (max-width: 365px) {
                    max-width: 140px;
                    padding: 0px 7px;
                }

                .submit_button{
                    width: 100%;
                    max-width: 200px;
                    height: 45px;
                    font-size: 16px;
                    line-height: 1;
                    color: #ffffff;
                    width: auto;
                    position: relative;
                    padding: 11px 25px;
                    border-radius: 4px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background-color: #0079FF;
                    border: 1px solid #0079FF;
                    transition: .3s all ease-in-out;
                    @media (max-width: 576px) {
                      height: 32px;
                    }
                    &:hover{
                        background-color: transparent;
                        border: 1px solid #0079FF;
                        color: #0079FF;
                    }
                    @media (max-width: 576px) {
                        font-size: 14px;
                        padding: 0px;
                    }
    
                }
            }
        }
    }
}