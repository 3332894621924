.image_wrapper{
    width: 100%;
    position: relative;
    .image_container{
        width: 100%;
        position: relative;
        padding-bottom: calc((172 / 322) * 100%);
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.image_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    
    // .image_container{
    //     width: 100%;
    //     position: relative;
    //     padding-bottom: calc((502 / 536) * 100%);
    //     img{
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         object-position: center;
    //     }
    // }


    .slider_wrapper{
        width: 100%;
        position: relative;
        max-width: 322px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 82px;
        padding-top: 40px;  

        @media (max-width: 1599px) {
            padding-top: 30px;
        }
      
        .slider_wrapper_inner{
            width: 100%;
            padding-bottom: calc((172 / 322) * 100%);
            position: relative;

            .slider_image{
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                :global(.slick-list){
                    height: 100%;
                    overflow: visible;
                }
                :global(.slick-dots){
                    position: absolute;
                    bottom: -35px;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    padding: 0;
                    align-items: center;
                    li{
                        font-size: 0px;
                        width: 6.42px;
                        height: 6.42px;
                        margin-right: 5px;
                        transition: .3s all ease-in-out;
                        button{
                            font-size: 0px;
                            width: 6.42px;
                            height: 6.42px;
                            padding: 3.2px;
                            border-radius: 50%;
                            overflow: hidden;
                            border: none;
                            background: rgb(26,87,250);
                            background: -moz-linear-gradient(45deg, rgba(26,87,250,1) 0%, rgba(50,172,252,1) 100%);
                            background: -webkit-linear-gradient(45deg, rgba(26,87,250,1) 0%, rgba(50,172,252,1) 100%);
                            background: linear-gradient(45deg, rgba(26,87,250,1) 0%, rgba(50,172,252,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1a57fa",endColorstr="#32acfc",GradientType=1);
                            transition: .3s all ease-in-out;
                            &::before{
                                display: none;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        &:global(.slick-active){
                            width: 12.83px;
                            height: 13.75px;
                            button{
                                width: 12.83px;
                                height: 13.75px;
                                background: rgb(41,27,151);
                                background: -moz-linear-gradient(45deg, rgba(41,27,151,1) 0%, rgba(83,54,204,1) 100%);
                                background: -webkit-linear-gradient(45deg, rgba(41,27,151,1) 0%, rgba(83,54,204,1) 100%);
                                background: linear-gradient(45deg, rgba(41,27,151,1) 0%, rgba(83,54,204,1) 100%);
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#291b97",endColorstr="#5336cc",GradientType=1);
                            }
                        }
                    }
                }
                :global(.slick-track){
                    transform: none !important;
                    width: auto !important;
                    height: 100%;
                    overflow: visible;
                }

                :global(.slick-slide){
                    width: 322px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    transition: .3s all ease-in-out;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    margin: auto;
                    pointer-events: none;
                    @media (max-width: 767px) {
                        width: 280px;
                    }
                    &:global(.slick-active){
                        transform: scale(1);
                        opacity: 1;
                        pointer-events: all;
                        & + :global(.slick-slide){
                            transform: scale(0.86);
                            opacity: 0.6;
                            top: -20px;
                        }
                        & + :global(.slick-slide) + :global(.slick-slide){
                            transform: scale(0.75);
                            opacity: 0.4;
                            top: -36px;
                        }
                        & + :global(.slick-slide) + :global(.slick-slide) + :global(.slick-slide){
                            transform: scale(.4);
                            opacity: .4;
                        }
                    }
                    
                    .image_wrapper{
                        width: 100%;
                        position: relative;
                        .image_container{
                            width: 100%;
                            position: relative;
                            padding-bottom: calc((172 / 322) * 100%);
                            img{
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }

            }
        }
    }
}