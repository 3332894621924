
.share_wrapper{
    width: 100%;
    position: relative;

    .share_wrapper_inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;


        .social_media_wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 30px;
            margin-bottom: 40px;
            @media (max-width: 576px) {
                margin-bottom: 20px;
            }

            ul{
                width: 100%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                max-width: 470px;
                justify-content: space-between;
                li{
                    width: auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    a{
                        width: 60px;
                        height: 60px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #ECECEE;
                        border-radius: 50%;
                        transition: .3s background-color ease-in-out;
                        &:hover{
                            background-color: rgba(236, 236, 238, 0.251);
                        }
                        @media (max-width: 1599px) {
                            width: 50px;
                            height: 50px;
                        }
                        @media (max-width: 1199px) {
                            width: 40px;
                            height: 40px;
                            img{
                                height: 20px;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }


        .copy_wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            max-width: 470px;
            margin-bottom: 40px;
            h3{
                font-size: 14px;
                line-height: 1;
                font-weight: 600;
                color: #272E43;
                margin: 0;
                margin-bottom: 10px;
            }
            .copy_input{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                input{
                    width: 100%;
                    border: 1px solid #ECECF0;
                    height: 45px;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    background-color: transparent;
                    padding: 12px;
                    padding-right: 80px;
                }
                .copy{
                    width: max-content;
                    background-color: transparent;
                    color: rgb(109, 108, 108);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    line-height: 1;
                    margin: auto;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    padding: 0px 10px;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }


        .action_wrapper{
            width: 100%;
            display: flex;
            position: relative;

            .cancel_btn{
                font-size: 16px;
                line-height: 1;
                color: #ffffff;
                width: auto;
                position: relative;
                padding: 11px 25px;
                border-radius: 4px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: #4B9AFA;
                border: none;
                margin-right: 10px;
            }
            .share_btn{
                font-size: 16px;
                line-height: 1;
                color: #ffffff;
                width: auto;
                position: relative;
                padding: 11px 25px;
                border-radius: 4px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: #4B9AFA;
                border: none;
            }
        }



    }
}




